/* BUSINESSES */
export const GET_BUSINESSES = "GET_BUSINESSES"
export const GET_BUSINESSES_SUCCESS = "GET_BUSINESSES_SUCCESS"
export const GET_BUSINESSES_FAIL = "GET_BUSINESSES_FAIL"

/**
 * add BUSINESS
 */
export const ADD_NEW_BUSINESS = "ADD_NEW_BUSINESS"
export const ADD_BUSINESS_SUCCESS = "ADD_BUSINESS_SUCCESS"
export const ADD_BUSINESS_FAIL = "ADD_BUSINESS_FAIL"

/**
 * Edit BUSINESS
 */
export const UPDATE_BUSINESS = "UPDATE_BUSINESS"
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS"
export const UPDATE_BUSINESS_FAIL = "UPDATE_BUSINESS_FAIL"

/**
 * Delete BUSINESS
 */
export const DELETE_BUSINESS = "DELETE_BUSINESS"
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS"
export const DELETE_BUSINESS_FAIL = "DELETE_BUSINESS_FAIL"
