import {
  GET_TERMINALS,
  GET_TERMINALS_FAIL,
  GET_TERMINALS_SUCCESS,
  ADD_NEW_TERMINAL,
  ADD_TERMINAL_SUCCESS,
  ADD_TERMINAL_FAIL,
  UPDATE_TERMINAL,
  UPDATE_TERMINAL_SUCCESS,
  UPDATE_TERMINAL_FAIL,
  DELETE_TERMINAL,
  DELETE_TERMINAL_SUCCESS,
  DELETE_TERMINAL_FAIL,
} from "./actionTypes"

export const getTerminals = () => ({
  type: GET_TERMINALS,
})

export const getTerminalsSuccess = terminals => ({
  type: GET_TERMINALS_SUCCESS,
  payload: terminals,
})

export const getTerminalsFail = error => ({
  type: GET_TERMINALS_FAIL,
  payload: error,
})

export const addNewTerminal = terminal => ({
  type: ADD_NEW_TERMINAL,
  payload: terminal,
})

export const addTerminalSuccess = terminal => ({
  type: ADD_TERMINAL_SUCCESS,
  payload: terminal,
})

export const addTerminalFail = error => ({
  type: ADD_TERMINAL_FAIL,
  payload: error,
})

export const updateTerminal = terminal => {
  const { _id, ...newTerminal } = terminal
  return {
    type: UPDATE_TERMINAL,
    payload: {
      id: _id,
      terminal: newTerminal,
    },
  }
}

export const updateTerminalSuccess = terminal => ({
  type: UPDATE_TERMINAL_SUCCESS,
  payload: terminal,
})

export const updateTerminalFail = error => ({
  type: UPDATE_TERMINAL_FAIL,
  payload: error,
})

export const deleteTerminal = terminal => {
  const { _id, ...newTerminal } = terminal
  return {
    type: DELETE_TERMINAL,
    payload: {
      id: _id,
      terminal: newTerminal,
    },
  }
}

export const deleteTerminalSuccess = terminal => ({
  type: DELETE_TERMINAL_SUCCESS,
  payload: terminal,
})

export const deleteTerminalFail = error => ({
  type: DELETE_TERMINAL_FAIL,
  payload: error,
})
