/* COMPANY USERS */
export const GET_COMPANY_USERS = "GET_COMPANY_USERS"
export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS"
export const GET_COMPANY_USERS_FAIL = "GET_COMPANY_USERS_FAIL"

/**
 * add COMPANY USER
 */
export const ADD_NEW_COMPANY_USER = "ADD_NEW_COMPANY_USER"
export const ADD_COMPANY_USER_SUCCESS = "ADD_COMPANY_USER_SUCCESS"
export const ADD_COMPANY_USER_FAIL = "ADD_COMPANY_USER_FAIL"

/**
 * Edit COMPANY_USER
 */
export const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER"
export const UPDATE_COMPANY_USER_SUCCESS = "UPDATE_COMPANY_USER_SUCCESS"
export const UPDATE_COMPANY_USER_FAIL = "UPDATE_COMPANY_USER_FAIL"

/**
 * Delete COMPANY_USER
 */
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER"
export const DELETE_COMPANY_USER_SUCCESS = "DELETE_COMPANY_USER_SUCCESS"
export const DELETE_COMPANY_USER_FAIL = "DELETE_COMPANY_USER_FAIL"
