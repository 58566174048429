import {
  GET_BUSINESSES,
  GET_BUSINESSES_FAIL,
  GET_BUSINESSES_SUCCESS,
  ADD_NEW_BUSINESS,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_FAIL,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_FAIL,
  DELETE_BUSINESS,
  DELETE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_FAIL,
} from "./actionTypes"

export const getBusinesses = () => ({
  type: GET_BUSINESSES,
})

export const getBusinessesSuccess = businesses => ({
  type: GET_BUSINESSES_SUCCESS,
  payload: businesses,
})

export const getBusinessesFail = error => ({
  type: GET_BUSINESSES_FAIL,
  payload: error,
})

export const addNewBusiness = business => ({
  type: ADD_NEW_BUSINESS,
  payload: business,
})

export const addBusinessSuccess = business => ({
  type: ADD_BUSINESS_SUCCESS,
  payload: business,
})

export const addBusinessFail = error => ({
  type: ADD_BUSINESS_FAIL,
  payload: error,
})

export const updateBusiness = business => ({
  type: UPDATE_BUSINESS,
  payload: {
    id: business._id,
    business: {
      name: business.name,
      description: business.description,
    },
  },
})

export const updateBusinessSuccess = business => ({
  type: UPDATE_BUSINESS_SUCCESS,
  payload: business,
})

export const updateBusinessFail = error => ({
  type: UPDATE_BUSINESS_FAIL,
  payload: error,
})

export const deleteBusiness = business => ({
  type: DELETE_BUSINESS,
  payload: {
    id: business._id,
    business: {
      name: business.name,
      description: business.description,
      company: business.company,
    },
  },
})

export const deleteBusinessSuccess = business => ({
  type: DELETE_BUSINESS_SUCCESS,
  payload: business,
})

export const deleteBusinessFail = error => ({
  type: DELETE_BUSINESS_FAIL,
  payload: error,
})
