/* STORES */
export const GET_STORES = "GET_STORES"
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS"
export const GET_STORES_FAIL = "GET_STORES_FAIL"

/**
 * add STORE
 */
export const ADD_NEW_STORE = "ADD_NEW_STORE"
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS"
export const ADD_STORE_FAIL = "ADD_STORE_FAIL"

/**
 * Edit STORE
 */
export const UPDATE_STORE = "UPDATE_STORE"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL"

/**
 * Delete STORE
 */
export const DELETE_STORE = "DELETE_STORE"
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS"
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL"
