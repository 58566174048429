import {
  GET_BUSINESSES_FAIL,
  GET_BUSINESSES_SUCCESS,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_FAIL,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_FAIL,
  DELETE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  businesses: [],
}

const Businesses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUSINESSES_SUCCESS:
      return {
        ...state,
        businesses: action.payload,
      }

    case GET_BUSINESSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: [...state.businesses, action.payload],
      }

    case ADD_BUSINESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: state.businesses.map(business =>
          business._id === action.payload._id.toString()
            ? { business, ...action.payload }
            : business
        ),
      }

    case UPDATE_BUSINESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: state.businesses.filter(
          business => business._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_BUSINESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Businesses
