/* REGIONS */
export const GET_REGIONS = "GET_REGIONS"
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS"
export const GET_REGIONS_FAIL = "GET_REGIONS_FAIL"

/**
 * add REGION
 */
export const ADD_NEW_REGION = "ADD_NEW_REGION"
export const ADD_REGION_SUCCESS = "ADD_REGION_SUCCESS"
export const ADD_REGION_FAIL = "ADD_REGION_FAIL"

/**
 * Edit REGION
 */
export const UPDATE_REGION = "UPDATE_REGION"
export const UPDATE_REGION_SUCCESS = "UPDATE_REGION_SUCCESS"
export const UPDATE_REGION_FAIL = "UPDATE_REGION_FAIL"

/**
 * Delete REGION
 */
export const DELETE_REGION = "DELETE_REGION"
export const DELETE_REGION_SUCCESS = "DELETE_REGION_SUCCESS"
export const DELETE_REGION_FAIL = "DELETE_REGION_FAIL"
