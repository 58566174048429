import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_DEVICES,
  ADD_NEW_DEVICE,
  DELETE_DEVICE,
  UPDATE_DEVICE,
} from "./actionTypes"
import {
  getDevicesFail,
  getDevicesSuccess,
  addDeviceFail,
  addDeviceSuccess,
  updateDeviceSuccess,
  updateDeviceFail,
  deleteDeviceSuccess,
  deleteDeviceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDevices,
  addNewDevice,
  updateDevice,
  deleteDevice,
} from "helpers/backend_helper"

function* fetchDevices() {
  try {
    const response = yield call(getDevices)
    console.log("RESPONSE: ", response)
    yield put(getDevicesSuccess(response))
  } catch (error) {
    yield put(getDevicesFail(error))
  }
}

function* onUpdateDevice({ payload }) {
  try {
    const response = yield call(updateDevice, payload)
    console.log("RESPONSE: ", response)
    yield put(updateDeviceSuccess(response.data))
  } catch (error) {
    yield put(updateDeviceFail(error))
  }
}

function* onDeleteDevice({ payload }) {
  try {
    const response = yield call(deleteDevice, payload)
    console.log("response", response)
    yield put(deleteDeviceSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteDeviceFail(error))
  }
}

function* onAddNewDevice({ payload: device }) {
  try {
    const response = yield call(addNewDevice, device)
    yield put(addDeviceSuccess(response.data))
  } catch (error) {
    yield put(addDeviceFail(error))
  }
}

function* devicesSaga() {
  yield takeEvery(GET_DEVICES, fetchDevices)
  yield takeEvery(ADD_NEW_DEVICE, onAddNewDevice)
  yield takeEvery(UPDATE_DEVICE, onUpdateDevice)
  yield takeEvery(DELETE_DEVICE, onDeleteDevice)
}

export default devicesSaga
