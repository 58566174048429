import {
  GET_OPTIONS,
  GET_OPTIONS_FAIL,
  GET_OPTIONS_SUCCESS,
  ADD_NEW_OPTION,
  ADD_OPTION_SUCCESS,
  ADD_OPTION_FAIL,
  UPDATE_OPTION,
  UPDATE_OPTION_SUCCESS,
  UPDATE_OPTION_FAIL,
  DELETE_OPTION,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_FAIL,
} from "./actionTypes"

export const getOptions = () => ({
  type: GET_OPTIONS,
})

export const getOptionsSuccess = options => ({
  type: GET_OPTIONS_SUCCESS,
  payload: options,
})

export const getOptionsFail = error => ({
  type: GET_OPTIONS_FAIL,
  payload: error,
})

export const addNewOption = option => ({
  type: ADD_NEW_OPTION,
  payload: option,
})

export const addOptionSuccess = option => ({
  type: ADD_OPTION_SUCCESS,
  payload: option,
})

export const addOptionFail = error => ({
  type: ADD_OPTION_FAIL,
  payload: error,
})

export const updateOption = option => {
  const { _id, ...newOption } = option
  return {
    type: UPDATE_OPTION,
    payload: {
      id: _id,
      option: newOption,
    },
  }
}

export const updateOptionSuccess = option => ({
  type: UPDATE_OPTION_SUCCESS,
  payload: option,
})

export const updateOptionFail = error => ({
  type: UPDATE_OPTION_FAIL,
  payload: error,
})

export const deleteOption = option => {
  const { _id, ...newOption } = option
  return {
    type: DELETE_OPTION,
    payload: {
      id: _id,
      option: newOption,
    },
  }
}

export const deleteOptionSuccess = option => ({
  type: DELETE_OPTION_SUCCESS,
  payload: option,
})

export const deleteOptionFail = error => ({
  type: DELETE_OPTION_FAIL,
  payload: error,
})
