import {
  GET_COMPANY_USERS,
  GET_COMPANY_USERS_FAIL,
  GET_COMPANY_USERS_SUCCESS,
  ADD_NEW_COMPANY_USER,
  ADD_COMPANY_USER_SUCCESS,
  ADD_COMPANY_USER_FAIL,
  UPDATE_COMPANY_USER,
  UPDATE_COMPANY_USER_SUCCESS,
  UPDATE_COMPANY_USER_FAIL,
  DELETE_COMPANY_USER,
  DELETE_COMPANY_USER_SUCCESS,
  DELETE_COMPANY_USER_FAIL,
} from "./actionTypes"

export const getCompanyUsers = () => ({
  type: GET_COMPANY_USERS,
})

export const getCompanyUsersSuccess = companyUsers => ({
  type: GET_COMPANY_USERS_SUCCESS,
  payload: companyUsers,
})

export const getCompanyUsersFail = error => ({
  type: GET_COMPANY_USERS_FAIL,
  payload: error,
})

export const addNewCompanyUser = companyUser => ({
  type: ADD_NEW_COMPANY_USER,
  payload: companyUser,
})

export const addCompanyUserSuccess = companyUser => ({
  type: ADD_COMPANY_USER_SUCCESS,
  payload: companyUser,
})

export const addCompanyUserFail = error => ({
  type: ADD_COMPANY_USER_FAIL,
  payload: error,
})

export const updateCompanyUser = companyUser => {
  const { _id, ...newCompanyUser } = companyUser
  return {
    type: UPDATE_COMPANY_USER,
    payload: {
      id: _id,
      companyUser: newCompanyUser,
    },
  }
}

export const updateCompanyUserSuccess = companyUser => ({
  type: UPDATE_COMPANY_USER_SUCCESS,
  payload: companyUser,
})

export const updateCompanyUserFail = error => ({
  type: UPDATE_COMPANY_USER_FAIL,
  payload: error,
})

export const deleteCompanyUser = companyUser => ({
  type: DELETE_COMPANY_USER,
  payload: {
    id: companyUser._id,
    companyUser: {
      name: companyUser.name,
      description: companyUser.description,
      deleted: companyUser.deleted,
    },
  },
})

export const deleteCompanyUserSuccess = companyUser => ({
  type: DELETE_COMPANY_USER_SUCCESS,
  payload: companyUser,
})

export const deleteCompanyUserFail = error => ({
  type: DELETE_COMPANY_USER_FAIL,
  payload: error,
})
