import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_PROFILES,
  ADD_NEW_PROFILE,
  DELETE_PROFILE,
  UPDATE_PROFILE,
} from "./actionTypes"
import {
  getProfilesFail,
  getProfilesSuccess,
  addProfileFail,
  addProfileSuccess,
  updateProfileSuccess,
  updateProfileFail,
  deleteProfileSuccess,
  deleteProfileFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProfiles,
  addNewProfile,
  updateProfile,
  deleteProfile,
} from "helpers/backend_helper"

function* fetchProfiles() {
  try {
    const response = yield call(getProfiles)
    console.log("RESPONSE: ", response)
    yield put(getProfilesSuccess(response))
  } catch (error) {
    yield put(getProfilesFail(error))
  }
}

function* onUpdateProfile({ payload }) {
  try {
    const response = yield call(updateProfile, payload)
    console.log("RESPONSE: ", response)
    yield put(updateProfileSuccess(response.data))
  } catch (error) {
    yield put(updateProfileFail(error))
  }
}

function* onDeleteProfile({ payload }) {
  try {
    const response = yield call(deleteProfile, payload)
    console.log("response", response)
    yield put(deleteProfileSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteProfileFail(error))
  }
}

function* onAddNewProfile({ payload: profile }) {
  try {
    const response = yield call(addNewProfile, profile)
    yield put(addProfileSuccess(response.data))
  } catch (error) {
    yield put(addProfileFail(error))
  }
}

function* profilesSaga() {
  yield takeEvery(GET_PROFILES, fetchProfiles)
  yield takeEvery(ADD_NEW_PROFILE, onAddNewProfile)
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
  yield takeEvery(DELETE_PROFILE, onDeleteProfile)
}

export default profilesSaga
