import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_OPTIONS,
  ADD_NEW_OPTION,
  DELETE_OPTION,
  UPDATE_OPTION,
} from "./actionTypes"
import {
  getOptionsFail,
  getOptionsSuccess,
  addOptionFail,
  addOptionSuccess,
  updateOptionSuccess,
  updateOptionFail,
  deleteOptionSuccess,
  deleteOptionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getOptions,
  addNewOption,
  updateOption,
  deleteOption,
} from "helpers/backend_helper"

function* fetchOptions() {
  try {
    const response = yield call(getOptions)
    yield put(getOptionsSuccess(response))
  } catch (error) {
    yield put(getOptionsFail(error))
  }
}

function* onUpdateOption({ payload }) {
  try {
    const response = yield call(updateOption, payload)
    console.log("RESPONSE: ", response)
    yield put(updateOptionSuccess(response.data))
  } catch (error) {
    yield put(updateOptionFail(error))
  }
}

function* onDeleteOption({ payload }) {
  try {
    const response = yield call(deleteOption, payload)
    console.log("response", response)
    yield put(deleteOptionSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteOptionFail(error))
  }
}

function* onAddNewOption({ payload: option }) {
  try {
    const response = yield call(addNewOption, option)
    yield put(addOptionSuccess(response.data))
  } catch (error) {
    yield put(addOptionFail(error))
  }
}

function* optionsSaga() {
  yield takeEvery(GET_OPTIONS, fetchOptions)
  yield takeEvery(ADD_NEW_OPTION, onAddNewOption)
  yield takeEvery(UPDATE_OPTION, onUpdateOption)
  yield takeEvery(DELETE_OPTION, onDeleteOption)
}

export default optionsSaga
