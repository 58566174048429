import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { FieldArray, Formik, useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getProfiles as onGetProfiles,
  addNewProfile as onAddNewProfile,
  updateProfile as onUpdateProfile,
  deleteProfile as onDeleteProfile,
  getCompanies as onGetCompanies,
} from "store/actions"
import ProfileDetailsModal from "./ProfileDetailsModal"
import { MultiSelectDataList } from "../../components/Common/MultiSelectDataList"
import { findConstant, getConstants } from "./helpers/getConstants"

const Profiles = props => {
  const dispatch = useDispatch()
  const isFirstTimeLoading = useRef(true)
  const [profileList, setProfileList] = useState([])
  const [profile, setProfile] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [modal, setModal] = useState(false)
  const [modalViewDetails, setViewDetailsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [homepage, setHomepage] = useState([])
  const [menus, setMenus] = useState([])
  const [tools, setTools] = useState([])
  const [reports, setReports] = useState([])

  const initialValues = {
    _id: (profile && profile._id) || "",
    name: (profile && profile.name) || "",
    company: (profile && profile.company) || "",
    homepage: (profile && profile.homepage) || "",
    menus: (profile && profile.menus) || "",
    reports: (profile && profile.reports) || "",
    tools: (profile && profile.tools) || "",
    intranet: (profile && profile.intranet) || "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Your Profile Name"),
    company: Yup.string().required("Please Select Your Company's Profile"),
    menus: Yup.array()
      .min(1, "Select at least one menu")
      .required("Select a menu"),
    reports: Yup.array().required("Please Select Your Menus' Reports"),
  })

  const onSubmit = (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    if (isEdit) {
      console.log("EDITING PROFILE: ", values)
      const updateProfile = {
        _id: profile._id,
        name: values.name,
        company: values.company,
        homepage: values.homepage,
        menus: values.menus.map(menu => menu.value),
        reports: values.reports.map(report => report.value),
        tools: values.tools.map(tool => tool.value),
        intranet: values.intranet,
      }
      // update profile
      dispatch(onUpdateProfile(updateProfile))
    } else {
      const newProfile = {
        name: values["name"],
        company: values["company"],
        homepage: values.homepage,
        menus: values.menus.map(menu => menu.value),
        reports: values.reports.map(report => report.value),
        tools: values.tools.map(tool => tool.value),
        intranet: values.intranet,
        deleted: false,
      }
      console.log("CREATING PROFILE: ", values)
      // save new profile
      dispatch(onAddNewProfile(newProfile))
    }
    resetForm()
    toggle()
  }

  const { profiles } = useSelector(state => ({
    profiles: state.profiles.profiles,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: profiles.length, // replace later with size(profiles),
    custom: true,
  }
  const { SearchBar } = Search

  const ProfileColumns = toggleModal => [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
    },
    {
      dataField: "homepage",
      text: "Homepage",
      sort: true,
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row.deleted ? "danger" : "success"}
          pill
        >
          {row.deleted ? "Deleted" : "Active"}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, profile) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleProfileDetails(profile)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, profile) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleProfileClick(profile)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(profile)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setProfile(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modalViewDetails) {
      setViewDetailsModal(false)
      setProfile(null)
    } else {
      setViewDetailsModal(true)
    }
  }

  const handleProfileClick = arg => {
    const profile = arg

    setProfile({
      _id: profile._id,
      name: profile.name,
      deleted: profile.deleted,
      company: profile.company,
      companyName: profile.companyName,
      homepage: profile.homepage,
      menus: profile.menus,
      reports: profile.reports,
      tools: profile.tools,
      intranet: profile.intranet,
      badgeclass: profile.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  const handleProfileDetails = arg => {
    const profile = arg

    setProfile({
      _id: profile._id,
      name: profile.name,
      company: profile.company,
      companyName: profile.companyName,
      deleted: profile.deleted,
      badgeclass: profile.badgeclass,
      homepage: profile.homepage,
      menus: profile.menus,
      reports: profile.reports,
      tools: profile.tools,
      intranet: profile.intranet,
    })

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete profile
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = profile => {
    setProfile(profile)
    setDeleteModal(true)
  }

  const handleDeleteProfile = () => {
    if (profile._id) {
      dispatch(onDeleteProfile(profile))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }
  const handleProfileClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "_id",
      profile: "desc",
    },
  ]

  useEffect(() => {
    if (isFirstTimeLoading.current && profiles) {
      isFirstTimeLoading.current = false
      dispatch(onGetProfiles())
    }
  }, [dispatch, profiles])

  useEffect(() => {
    if (profiles) {
      const newProfiles = profiles
        .filter(profile => !profile.deleted)
        .map(profile => ({
          ...profile,
          menus: profile.menus.map(menu => findConstant("menusList", menu)),
          tools: profile.tools.map(tool => findConstant("toolsList", tool)),
          reports: profile.reports.map(report =>
            findConstant("reportsList", report)
          ),
          companyName: companies.find(
            company => company._id.toString() == profile.company.toString()
          )?.name,
        }))
      setProfileList(newProfiles)
    }
  }, [profiles, companies])

  useEffect(() => {
    const { homepageList, menusList, toolsList, reportsList } = getConstants()
    setHomepage(homepageList)
    setMenus(menusList)
    setTools(toolsList)
    setReports(reportsList)
  }, [])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (companies) {
      const newCompanies = companies.filter(company => !company.deleted)
      setCompanyList(newCompanies)
    }
  }, [companies])

  return (
    <React.Fragment>
      <ProfileDetailsModal
        isOpen={modalViewDetails}
        toggle={toggleViewModal}
        profile={profile}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProfile}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Profiles</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Profiles" breadcrumbItem="Profiles" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProfileColumns(toggle)}
                    data={profileList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={profileList}
                        columns={ProfileColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleProfileClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Profile
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bprofileed={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Profile" : "Add Profile"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Formik
                                      enableReinitialize={true}
                                      initialValues={initialValues}
                                      validationSchema={validationSchema}
                                      onSubmit={onSubmit}
                                    >
                                      {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        setTouched,
                                        handleSubmit,
                                      }) => (
                                        <Form>
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Profile Name
                                                </Label>
                                                <Input
                                                  name="name"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.name || ""}
                                                  invalid={
                                                    touched.name && errors.name
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {touched.name && errors.name ? (
                                                  <FormFeedback type="invalid">
                                                    {errors.name}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              {!isEdit && (
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Company
                                                  </Label>
                                                  <Input
                                                    name="company"
                                                    type="select"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    invalid={
                                                      touched.company &&
                                                      errors.company
                                                        ? true
                                                        : false
                                                    }
                                                    className="form-select"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.company || ""}
                                                  >
                                                    <option value="" key="none">
                                                      ...Please select a Company
                                                    </option>
                                                    {companyList.map(
                                                      (company, index) => (
                                                        <option
                                                          value={company._id}
                                                          key={company._id}
                                                        >
                                                          {company.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </Input>
                                                  {touched.company &&
                                                  errors.company ? (
                                                    <FormFeedback type="invalid">
                                                      {errors.company}
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              )}

                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  HomePage
                                                </Label>
                                                <Input
                                                  name="homepage"
                                                  type="select"
                                                  className="form-select"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={values.homepage || ""}
                                                >
                                                  <option value="" key="none">
                                                    ...Please select a HomePage
                                                  </option>
                                                  {homepage.map(
                                                    (homepage, index) => (
                                                      <option
                                                        value={homepage.value}
                                                        key={index}
                                                      >
                                                        {homepage.name}
                                                      </option>
                                                    )
                                                  )}
                                                </Input>
                                              </div>

                                              <div className="mb-3">
                                                <FieldArray name="menus">
                                                  {fieldArrayOptions => (
                                                    <MultiSelectDataList
                                                      onBlur={handleBlur}
                                                      options={menus}
                                                      selectedOptions={
                                                        values.menus
                                                      }
                                                      name="menus"
                                                      label="Menus"
                                                      {...fieldArrayOptions}
                                                      touched={touched.menus}
                                                      errors={errors.menus}
                                                      setTouched={() =>
                                                        setTouched({
                                                          ...touched,
                                                          menus: true,
                                                        })
                                                      }
                                                    />
                                                  )}
                                                </FieldArray>
                                              </div>
                                              <div className="mb-3">
                                                <FieldArray name="reports">
                                                  {fieldArrayOptions => (
                                                    <MultiSelectDataList
                                                      options={reports}
                                                      selectedOptions={
                                                        values.reports
                                                      }
                                                      name="reports"
                                                      label="Reports"
                                                      {...fieldArrayOptions}
                                                    />
                                                  )}
                                                </FieldArray>
                                                {touched.reports &&
                                                errors.reports ? (
                                                  <FormFeedback type="invalid">
                                                    {errors.reports}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <FieldArray name="tools">
                                                  {fieldArrayOptions => (
                                                    <MultiSelectDataList
                                                      options={tools}
                                                      selectedOptions={
                                                        values.tools
                                                      }
                                                      name="tools"
                                                      label="e-Buzz"
                                                      {...fieldArrayOptions}
                                                    />
                                                  )}
                                                </FieldArray>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  onClick={e => {
                                                    e.preventDefault()
                                                    handleSubmit()
                                                  }}
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      )}
                                    </Formik>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Profiles.propTypes = {
  profiles: PropTypes.array,
  onGetProfiles: PropTypes.func,
  onAddNewProfile: PropTypes.func,
  onDeleteProfile: PropTypes.func,
  onUpdateProfile: PropTypes.func,
}

export default withRouter(Profiles)
