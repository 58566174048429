import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_REGIONS,
  ADD_NEW_REGION,
  DELETE_REGION,
  UPDATE_REGION,
} from "./actionTypes"
import {
  getRegionsFail,
  getRegionsSuccess,
  addRegionFail,
  addRegionSuccess,
  updateRegionSuccess,
  updateRegionFail,
  deleteRegionSuccess,
  deleteRegionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRegions,
  addNewRegion,
  updateRegion,
  deleteRegion,
} from "helpers/backend_helper"

function* fetchRegions() {
  try {
    const response = yield call(getRegions)
    yield put(getRegionsSuccess(response))
  } catch (error) {
    yield put(getRegionsFail(error))
  }
}

function* onUpdateRegion({ payload }) {
  try {
    const response = yield call(updateRegion, payload)
    console.log("RESPONSE: ", response)
    yield put(updateRegionSuccess(response.data))
  } catch (error) {
    yield put(updateRegionFail(error))
  }
}

function* onDeleteRegion({ payload }) {
  try {
    const response = yield call(deleteRegion, payload)
    console.log("response", response)
    yield put(deleteRegionSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteRegionFail(error))
  }
}

function* onAddNewRegion({ payload: region }) {
  try {
    const response = yield call(addNewRegion, region)
    yield put(addRegionSuccess(response.data))
  } catch (error) {
    yield put(addRegionFail(error))
  }
}

function* regionsSaga() {
  yield takeEvery(GET_REGIONS, fetchRegions)
  yield takeEvery(ADD_NEW_REGION, onAddNewRegion)
  yield takeEvery(UPDATE_REGION, onUpdateRegion)
  yield takeEvery(DELETE_REGION, onDeleteRegion)
}

export default regionsSaga
