import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("auth-evms-user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const loginUser = data => post(url.LOGIN_USER, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Register Method
const postJwtRegister = async (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// get companies
export const getCompanies = () => get(url.GET_COMPANIES)

// add company
export const addNewCompany = company => post(url.ADD_NEW_COMPANY, company)

// update company
export const updateCompany = ({ id, company }) =>
  put(`${url.UPDATE_COMPANY}/${id}`, company)

// delete company
export const deleteCompany = ({ id, company }) =>
  del(`${url.DELETE_COMPANY}/${id}`, company)

// get businesses
export const getBusinesses = () => get(url.GET_BUSINESSES)

// add business
export const addNewBusiness = business => post(url.ADD_NEW_BUSINESS, business)

// update business
export const updateBusiness = ({ id, business }) =>
  put(`${url.UPDATE_BUSINESS}/${id}`, business)

// delete business
export const deleteBusiness = ({ id, business }) =>
  del(`${url.DELETE_BUSINESS}/${id}`, business)

// get regions
export const getRegions = () => get(url.GET_REGIONS)

// add region
export const addNewRegion = region => post(url.ADD_NEW_REGION, region)

// update region
export const updateRegion = ({ id, region }) =>
  put(`${url.UPDATE_REGION}/${id}`, region)

// delete region
export const deleteRegion = ({ id, region }) =>
  del(`${url.DELETE_REGION}/${id}`, region)

// get stores
export const getStores = () => get(url.GET_STORES)

// add store
export const addNewStore = store => post(url.ADD_NEW_STORE, store)

// update store
export const updateStore = ({ id, store }) =>
  put(`${url.UPDATE_STORE}/${id}`, store)

// delete store
export const deleteStore = ({ id, store }) =>
  del(`${url.DELETE_STORE}/${id}`, store)

// get departments
export const getDepartments = () => get(url.GET_DEPARTMENTS)

// add department
export const addNewDepartment = department =>
  post(url.ADD_NEW_DEPARTMENT, department)

// update department
export const updateDepartment = ({ id, department }) =>
  put(`${url.UPDATE_DEPARTMENT}/${id}`, department)

// delete department
export const deleteDepartment = ({ id, department }) =>
  del(`${url.DELETE_DEPARTMENT}/${id}`, department)

// get company users
export const getCompanyUsers = () => get(url.GET_COMPANY_USERS)

// add companyUser
export const addNewCompanyUser = companyUser =>
  post(url.ADD_NEW_COMPANY_USER, companyUser)

// update companyUser
export const updateCompanyUser = ({ id, companyUser }) =>
  put(`${url.UPDATE_COMPANY_USER}/${id}`, companyUser)

// delete companyUser
export const deleteCompanyUser = ({ id, companyUser }) =>
  del(`${url.DELETE_COMPANY_USER}/${id}`, companyUser)

// get e-buttons
export const getEButtons = () => get(url.GET_EBUTTONS)

// add e-buttons
export const addNewEButton = eButtons => post(url.ADD_NEW_EBUTTON, eButtons)

// update e-buttons
export const updateEButton = ({ id, eButtons }) =>
  put(`${url.UPDATE_EBUTTON}/${id}`, eButtons)

// delete e-buttons
export const deleteEButton = ({ id, eButtons }) =>
  del(`${url.DELETE_EBUTTON}/${id}`, eButtons)

// get devices
export const getDevices = () => get(url.GET_DEVICES)

// add devices
export const addNewDevice = device => post(url.ADD_NEW_DEVICE, device)

// update devices
export const updateDevice = ({ id, device }) =>
  put(`${url.UPDATE_DEVICE}/${id}`, device)

// delete devices
export const deleteDevice = ({ id, device }) =>
  del(`${url.DELETE_DEVICE}/${id}`, device)
  
// get profiles
export const getProfiles = () => get(url.GET_PROFILES)

// add profile
export const addNewProfile = profile => post(url.ADD_NEW_PROFILE, profile)

// update profile
export const updateProfile = ({ id, profile }) =>
  put(`${url.UPDATE_PROFILE}/${id}`, profile)

// delete profile
export const deleteProfile = ({ id, profile }) =>
  del(`${url.DELETE_PROFILE}/${id}`, profile)

// get terminals
export const getTerminals = () => get(url.GET_TERMINALS)

// add terminal
export const addNewTerminal = terminal => post(url.ADD_NEW_TERMINAL, terminal)

// update terminal
export const updateTerminal = ({ id, terminal }) =>
  put(`${url.UPDATE_TERMINAL}/${id}`, terminal)

// delete terminal
export const deleteTerminal = ({ id, terminal }) =>
  del(`${url.DELETE_TERMINAL}/${id}`, terminal)

// get options
export const getOptions = () => get(url.GET_OPTIONS)

// add profile
export const addNewOption = option => post(url.ADD_NEW_OPTION, option)

// update option
export const updateOption = ({ id, option }) =>
  put(`${url.UPDATE_OPTION}/${id}`, option)

// delete option
export const deleteOption = ({ id, option }) =>
  del(`${url.DELETE_OPTION}/${id}`, option)

export {
  getLoggedInUser,
  isUserAuthenticated,
  loginUser,
  postJwtRegister,
  postFakeProfile,
  postJwtForgetPwd,
  postFakeForgetPwd,
  postFakeRegister,
  postJwtProfile,
}
