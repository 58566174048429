import {
  GET_REGIONS,
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  ADD_NEW_REGION,
  ADD_REGION_SUCCESS,
  ADD_REGION_FAIL,
  UPDATE_REGION,
  UPDATE_REGION_SUCCESS,
  UPDATE_REGION_FAIL,
  DELETE_REGION,
  DELETE_REGION_SUCCESS,
  DELETE_REGION_FAIL,
} from "./actionTypes"

export const getRegions = () => ({
  type: GET_REGIONS,
})

export const getRegionsSuccess = regions => ({
  type: GET_REGIONS_SUCCESS,
  payload: regions,
})

export const getRegionsFail = error => ({
  type: GET_REGIONS_FAIL,
  payload: error,
})

export const addNewRegion = region => ({
  type: ADD_NEW_REGION,
  payload: region,
})

export const addRegionSuccess = region => ({
  type: ADD_REGION_SUCCESS,
  payload: region,
})

export const addRegionFail = error => ({
  type: ADD_REGION_FAIL,
  payload: error,
})

export const updateRegion = region => ({
  type: UPDATE_REGION,
  payload: {
    id: region._id,
    region: {
      name: region.name,
      description: region.description,
    },
  },
})

export const updateRegionSuccess = region => ({
  type: UPDATE_REGION_SUCCESS,
  payload: region,
})

export const updateRegionFail = error => ({
  type: UPDATE_REGION_FAIL,
  payload: error,
})

export const deleteRegion = region => ({
  type: DELETE_REGION,
  payload: {
    id: region._id,
    region: {
      name: region.name,
      description: region.description,
    },
  },
})

export const deleteRegionSuccess = region => ({
  type: DELETE_REGION_SUCCESS,
  payload: region,
})

export const deleteRegionFail = error => ({
  type: DELETE_REGION_FAIL,
  payload: error,
})
