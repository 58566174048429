import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_DEPARTMENTS,
  ADD_NEW_DEPARTMENT,
  DELETE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "./actionTypes"
import {
  getDepartmentsFail,
  getDepartmentsSuccess,
  addDepartmentFail,
  addDepartmentSuccess,
  updateDepartmentSuccess,
  updateDepartmentFail,
  deleteDepartmentSuccess,
  deleteDepartmentFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDepartments,
  addNewDepartment,
  updateDepartment,
  deleteDepartment,
} from "helpers/backend_helper"

function* fetchDepartments() {
  try {
    const response = yield call(getDepartments)
    yield put(getDepartmentsSuccess(response))
  } catch (error) {
    yield put(getDepartmentsFail(error))
  }
}

function* onUpdateDepartment({ payload }) {
  try {
    const response = yield call(updateDepartment, payload)
    yield put(updateDepartmentSuccess(response.data))
  } catch (error) {
    yield put(updateDepartmentFail(error))
  }
}

function* onDeleteDepartment({ payload }) {
  try {
    const response = yield call(deleteDepartment, payload)
    console.log("response", response)
    yield put(deleteDepartmentSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteDepartmentFail(error))
  }
}

function* onAddNewDepartment({ payload: department }) {
  try {
    const response = yield call(addNewDepartment, department)
    yield put(addDepartmentSuccess(response.data))
  } catch (error) {
    yield put(addDepartmentFail(error))
  }
}

function* departmentsSaga() {
  yield takeEvery(GET_DEPARTMENTS, fetchDepartments)
  yield takeEvery(ADD_NEW_DEPARTMENT, onAddNewDepartment)
  yield takeEvery(UPDATE_DEPARTMENT, onUpdateDepartment)
  yield takeEvery(DELETE_DEPARTMENT, onDeleteDepartment)
}

export default departmentsSaga
