import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { loginUser as postLogin } from "../../../helpers/backend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      username: user.username,
      password: user.password,
      code: user.code,
    })
    if(response.data.scope === true) {
      localStorage.setItem("auth-evms-user", JSON.stringify(response))
      yield put(loginSuccess(response))
      history.push("/companies")
    } else {
      toastr.error("The user does not have permission", "Failed Login")
      history.push("/login")
    }
  } catch (error) {
    toastr.options = {
      closeButton: true,
      debug: false,
      extendedTimeOut: "1000",
      hideDuration: "1000",
      hideEasing: "linear",
      hideMethod: "fadeOut",
      newestOnTop: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      progressBar: false,
      showDuration: "300",
      showEasing: "swing",
      showMethod: "fadeIn",
      timeOut: "5000",
    }
    toastr.error("Username, Password or Code Incorrect", "Failed Login")
    yield put(apiError(error))
    history.push("/login")
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("auth-evms-user")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
