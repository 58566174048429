import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const OptionDetailsModal = props => {
  const { isOpen, toggle, option } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Option Details</ModalHeader>
        <ModalBody>
          <h6 className="mb-2">
            Option id: <span className="text-primary">#{option?._id}</span>
          </h6>
          <h6 className="mb-4">
            Type: <span className="text-primary">{option?.segmentType}</span>
          </h6>
          <p className="mb-2">
            Company: <span className="text-primary">{option?.companyName}</span>
          </p>
          <p className="mb-2">
            Store: <span className="text-primary">{option?.storeName}</span>
          </p>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Values:</p>
            {option?.values &&
              option.values.map(currentValue => (
                <button
                  key={currentValue}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {currentValue}
                </button>
              ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

OptionDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  option: PropTypes.object,
}

export default OptionDetailsModal
