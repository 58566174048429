import {
  GET_EBUTTONS_FAIL,
  GET_EBUTTONS_SUCCESS,
  ADD_EBUTTON_SUCCESS,
  ADD_EBUTTON_FAIL,
  UPDATE_EBUTTON_SUCCESS,
  UPDATE_EBUTTON_FAIL,
  DELETE_EBUTTON_SUCCESS,
  DELETE_EBUTTON_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  eButtons: [],
}

const EButtons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EBUTTONS_SUCCESS:
      return {
        ...state,
        eButtons: action.payload,
      }

    case GET_EBUTTONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EBUTTON_SUCCESS:
      return {
        ...state,
        eButtons: [...state.eButtons, action.payload],
      }

    case ADD_EBUTTON_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EBUTTON_SUCCESS:
      return {
        ...state,
        eButtons: state.eButtons.map(eButton =>
          eButton._id === action.payload._id.toString()
            ? { eButton, ...action.payload }
            : eButton
        ),
      }

    case UPDATE_EBUTTON_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EBUTTON_SUCCESS:
      return {
        ...state,
        eButtons: state.eButtons.filter(
          eButton => eButton._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_EBUTTON_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default EButtons
