import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { FieldArray, Formik, useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"
import { MultiSelect } from "../../components/Common/MultiSelect"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getEButtons as onGetEButtons,
  addNewEButton as onAddNewEButton,
  updateEButton as onUpdateEButton,
  deleteEButton as onDeleteEButton,
  getCompanies as onGetCompanies,
  getProfiles as onGetProfiles,
  getCompanyUsers as onGetCompanyUsers,
} from "store/actions"
import EButtonDetailsModal from "./EButtonDetailsModal"

const EButtons = props => {
  const dispatch = useDispatch()
  const isFirstTimeLoading = useRef(true)
  const [eButton, setEButton] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalViewDetails, setViewDetailsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [dwSwitch, setDwSwitch] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [profileList, setProfileList] = useState([])
  const [usersList, setUsersList] = useState([])

  const optionsAction = [
    { value: 'SMS', label: 'SMS' },
    { value: 'EMAIL', label: 'EMAIL' },
    { value: 'NOTIFICATION', label: 'NOTIFICATION' },
  ]
  
  const optionsTypes = [
    { value: 'EYESON360', label: 'EyesOn 360' },
    { value: 'eButton', label: 'eButton' },
  ]

  const { eButtons } = useSelector(state => ({
    eButtons: state.eButtons.eButtons,
  }))

  const { profiles } = useSelector(state => ({
    profiles: state.profiles.profiles,
  }))

  const { companyUsers } = useSelector(state => ({
    companyUsers: state.companyUsers.companyUsers,
  }))

  const { SearchBar } = Search

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: (eButton && eButton._id) || "",
      label: (eButton && eButton.label) || "",
      description: (eButton && eButton.description) || "",
      message: (eButton && eButton.message) || "",
      action: (eButton && eButton.action) || [],
      type: (eButton && eButton.type) || "",
      profile: (eButton && eButton.profile) || "",
      profileRe: (eButton && eButton.profileRe) || "",
      users: (eButton && eButton.users) || "",
      dwCamera: (eButton && eButton.dwCamera) || "",
      dwServer: (eButton && eButton.dwServer) || "",
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Please Enter Your Label"),
      description: Yup.string().required("Please Enter Your Description"),
      message: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      const payload = {
        label: values.label,
        description: values.description,
        message: values.message,
        dwCamera: values.dwCamera,
        dwServer: values.dwServer,
        action: values.action,
        type: values.type,
        profile: values.profile,
        profileRe: values.profileRe,
        users: values.users,
      }
      if (isEdit) {
        console.log("EDITING e-button: ", {...payload, _id: eButton._id})
        dispatch(onUpdateEButton({...payload, _id: eButton._id}))
      } else {
        console.log("CREATING e-button: ", payload)
        dispatch(onAddNewEButton(payload))
      }
      validation.resetForm()
      toggle()
    },
  })

  const EButtonsColumns = toggleModal => [
    {
      dataField: "label",
      text: "Label",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, eButton) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleEButtonDetails(eButton)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, eButton) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleEButtonClick(eButton)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(eButton)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setEButton(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modalViewDetails) {
      setViewDetailsModal(false)
      setEButton(null)
    } else {
      setViewDetailsModal(true)
    }
  }

  const handleEButtonClick = arg => {
    const eButton = arg

    setEButton({
      _id: eButton._id,
      label: eButton.label,
      description: eButton.description,
      message: eButton.message,
      profile: eButton.profile,
      profileRe: eButton.profileRe,
      type: eButton.type,
      users: eButton.users,
      action: eButton.action,
      dwCamera: eButton.dwCamera,
      dwServer: eButton.dwServer,
    })

    if(eButton.dwCamera || eButton.dwServer) setDwSwitch(true)

    setIsEdit(true)

    toggle()
  }

  const handleEButtonDetails = arg => {
    const eButton = arg

    setEButton({
      _id: eButton._id,
      label: eButton.label,
      description: eButton.description,
      message: eButton.message,
      profile: eButton.profile,
      type: eButton.type,
      users: eButton.users,
      action: eButton.action,
      dwCamera: eButton.dwCamera,
      dwServer: eButton.dwServer,
    })

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const onClickDelete = eButton => {
    setEButton(eButton)
    setDeleteModal(true)
  }

  const handleDeleteEButton = () => {
    if (eButton._id) {
      dispatch(onDeleteEButton(eButton))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }

  const handleEbuttonClicks = () => {
    setEButton(null)
    setDwSwitch(false)
    setIsEdit(false)
    toggle()
  }

  useEffect(() => {
    if (companyUsers && !companyUsers.length) {
      dispatch(onGetCompanyUsers())
    }
  }, [dispatch, companyUsers])

  useEffect(() => {
    if (companyUsers) {
      const activeProfiles = companyUsers.map(
        user => ({ value: user._id, label: `${user.firstname} ${user.lastname}` })
      )
      setUsersList(activeProfiles)
    }
  }, [companyUsers])

  useEffect(() => {
    if (profiles && !profiles.length) {
      dispatch(onGetProfiles())
    }
  }, [dispatch, profiles])

  useEffect(() => {
    if (profiles) {
      const activeProfiles = profiles.map(
        profile => ({ value: profile._id, label: profile.name })
      )
      setProfileList(activeProfiles)
    }
  }, [profiles])

  useEffect(() => {
    if (isFirstTimeLoading.current && eButtons) {
      isFirstTimeLoading.current = false
      dispatch(onGetEButtons())
    }
  }, [dispatch, eButtons])

  return (
    <React.Fragment>
      <EButtonDetailsModal
        isOpen={modalViewDetails}
        toggle={toggleViewModal}
        eButton={eButton}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEButton}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>e-Buttons</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="e-Buttons" breadcrumbItem="e-Buttons" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory({ sizePerPage: 10, totalSize: eButtons.length, custom: true })}
                    keyField="id"
                    columns={EButtonsColumns(toggle)}
                    data={eButtons}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={eButtons}
                        columns={EButtonsColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleEbuttonClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add e-button
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bprofileed={false}
                                    striped={false}
                                    defaultSorted={[{dataField: "_id", eButton: "desc"}]}
                                    selectRow={{mode: "checkbox"}}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit e-button" : "Add e-button"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Label
                                            </Label>
                                            <Input
                                              name="label"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.label ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched.label &&
                                                validation.errors.label
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.errors.label ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.label}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Description
                                            </Label>
                                            <Input
                                              name="description"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.description ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched.description &&
                                                validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Message
                                            </Label>
                                            <Input
                                              name="message"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.message ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched.message &&
                                                validation.errors.message
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.errors.message ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.message}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Action"
                                              name="action"
                                              value={
                                                validation.values.action
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "action",
                                                  newValues
                                                )
                                              }
                                              options={optionsAction}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Type"
                                              name="type"
                                              value={
                                                validation.values.type
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "type",
                                                  newValues
                                                )
                                              }
                                              options={optionsTypes}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Available To"
                                              name="profile"
                                              value={
                                                validation.values.profile
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "profile",
                                                  newValues
                                                )
                                              }
                                              options={profileList}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Recipients (Users)"
                                              name="users"
                                              value={
                                                validation.values.users
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "users",
                                                  newValues
                                                )
                                              }
                                              options={usersList}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Recipients (Profiles)"
                                              name="profileRe"
                                              value={
                                                validation.values.profileRe
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "profileRe",
                                                  newValues
                                                )
                                              }
                                              options={profileList}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <div className="form-check form-switch form-switch-md">
                                              <label className="form-check-label" htmlFor="dwSwitch">DWIntegration</label>
                                              <input className="form-check-input" type="checkbox" id="dwSwitch" value={dwSwitch} defaultChecked={dwSwitch}  onChange={(e) => setDwSwitch(e.target.checked)}/>
                                            </div>
                                          </div>
                                          {dwSwitch && (
                                            <>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  DwCamera
                                                </Label>
                                                <Input
                                                  name="dwCamera"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.dwCamera ||
                                                    ""
                                                  }
                                                />
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  DwServer
                                                </Label>
                                                <Input
                                                  name="dwServer"
                                                  type="text"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.dwServer ||
                                                    ""
                                                  }
                                                />
                                              </div>
                                            </>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EButtons.propTypes = {
  eButtons: PropTypes.array,
  onGetEButtons: PropTypes.func,
  onAddNewEButton: PropTypes.func,
  onDeleteEButton: PropTypes.func,
  onUpdateEButton: PropTypes.func,
  onGetProfiles: PropTypes.func,
  onGetCompanyUsers: PropTypes.func,
}

export default withRouter(EButtons)
