import {
  GET_DEVICES,
  GET_DEVICES_FAIL,
  GET_DEVICES_SUCCESS,
  ADD_NEW_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  UPDATE_DEVICE,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
} from "./actionTypes"

export const getDevices = () => ({
  type: GET_DEVICES,
})

export const getDevicesSuccess = device => ({
  type: GET_DEVICES_SUCCESS,
  payload: device,
})

export const getDevicesFail = error => ({
  type: GET_DEVICES_FAIL,
  payload: error,
})

export const addNewDevice = device => ({
  type: ADD_NEW_DEVICE,
  payload: device,
})

export const addDeviceSuccess = device => ({
  type: ADD_DEVICE_SUCCESS,
  payload: device,
})

export const addDeviceFail = error => ({
  type: ADD_DEVICE_FAIL,
  payload: error,
})

export const updateDevice = device => {
  const { _id, ...newDevice } = device
  return {
    type: UPDATE_DEVICE,
    payload: {
      id: _id,
      device: newDevice,
    },
  }
}

export const updateDeviceSuccess = device => ({
  type: UPDATE_DEVICE_SUCCESS,
  payload: device,
})

export const updateDeviceFail = error => ({
  type: UPDATE_DEVICE_FAIL,
  payload: error,
})

export const deleteDevice = device => {
  const { _id, ...deviceWithoutId } = device
  return {
    type: DELETE_DEVICE,
    payload: {
      id: _id,
      device: deviceWithoutId,
    },
  }
}

export const deleteDeviceSuccess = device => ({
  type: DELETE_DEVICE_SUCCESS,
  payload: device,
})

export const deleteDeviceFail = error => ({
  type: DELETE_DEVICE_FAIL,
  payload: error,
})
