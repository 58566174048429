import axios from "axios"

//apply base url for axios
const API_URL = "https://evms.eyeson.biz/api"
const API_URL_login = "https://evms.eyeson.biz/api"

const axiosApiLogin = axios.create({
  baseURL: API_URL_login,
})

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  config => {
    if (JSON.parse(localStorage.getItem("auth-evms-user"))) {
      config.headers.common["Authorization"] = `Bearer ${
        JSON.parse(localStorage.getItem("auth-evms-user")).token
      }`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem("auth-evms-user")
      window.location.href = "/login"
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  if (url == '/auth/login') return await axiosApiLogin.post(url, { ...data }, { ...config })
  .then(response => response.data)
  else return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
