import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const TerminalDetailsModal = props => {
  const { isOpen, toggle, terminal } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Terminal Details</ModalHeader>
        <ModalBody>
          <h6 className="mb-2">
            Terminal id: <span className="text-primary">#{terminal?._id}</span>
          </h6>
          <h6 className="mb-2">
            Register: <span className="text-primary">{terminal?.register}</span>
          </h6>
          <h6 className="mb-2">
            Register Label:{" "}
            <span className="text-primary">{terminal?.registerLabel}</span>
          </h6>
          <h6 className="mb-4">
            IP: <span className="text-primary">{terminal?.ip}</span>
          </h6>
          <p className="mb-2">
            Company:{" "}
            <span className="text-primary">{terminal?.companyName}</span>
          </p>
          <p className="mb-2">
            Business:{" "}
            <span className="text-primary">{terminal?.businessName}</span>
          </p>
          <p className="mb-2">
            Region: <span className="text-primary">{terminal?.regionName}</span>
          </p>
          <p className="mb-2">
            Store: <span className="text-primary">{terminal?.storeName}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

TerminalDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  terminal: PropTypes.object,
}

export default TerminalDetailsModal
