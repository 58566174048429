import {
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  ADD_REGION_SUCCESS,
  ADD_REGION_FAIL,
  UPDATE_REGION_SUCCESS,
  UPDATE_REGION_FAIL,
  DELETE_REGION_SUCCESS,
  DELETE_REGION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  regions: [],
}

const Regions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload,
      }

    case GET_REGIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_REGION_SUCCESS:
      return {
        ...state,
        regions: [...state.regions, action.payload],
      }

    case ADD_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_REGION_SUCCESS:
      return {
        ...state,
        regions: state.regions.map(region =>
          region._id === action.payload._id.toString()
            ? { region, ...action.payload }
            : region
        ),
      }

    case UPDATE_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REGION_SUCCESS:
      return {
        ...state,
        regions: state.regions.filter(
          region => region._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Regions
