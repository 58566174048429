import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getDepartments as onGetDepartments,
  getCompanies as onGetCompanies,
  addNewDepartment as onAddNewDepartment,
  updateDepartment as onUpdateDepartment,
  deleteDepartment as onDeleteDepartment,
} from "store/actions"
import DepartmentDetailsModal from "./DepartmentDetailsModal"

const Departments = props => {
  const dispatch = useDispatch()

  const [departmentList, setDepartmentList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [department, setDepartment] = useState(null)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  //delete department
  const [deleteModal, setDeleteModal] = useState(false)

  const { departments } = useSelector(state => ({
    departments: state.departments.departments,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: (department && department._id) || "",
      name: (department && department.name) || "",
      description: (department && department.description) || "",
      company:
        (department && department.company) ||
        (companyList.length !== 0 && companyList[0]._id) ||
        "",
      deleted: (department && department.deleted) || "Active",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Department Name"),
      description: Yup.string().required(
        "Please Enter Your Department Description"
      ),
      company: Yup.string().required("Please Select a Company"),
      deleted: Yup.string().required("Please Enter Your Department Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        console.log("EDITING DEPARTMENT: ", values)
        const updateDepartment = {
          _id: department._id,
          name: values.name,
          description: values.description,
          company: values.company,
        }
        // update department
        dispatch(onUpdateDepartment(updateDepartment))
        validation.resetForm()
      } else {
        const newDepartment = {
          name: values["name"],
          description: values["description"],
          company: values["company"],
        }
        console.log("CREATING DEPARTMENT: ", values)
        // save new department
        dispatch(onAddNewDepartment(newDepartment))
        validation.resetForm()
      }
      toggle()
    },
  })

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: departments.length, // replace later with size(departments),
    custom: true,
  }
  const { SearchBar } = Search

  const toggleViewModal = () => setModal1(!modal1)

  const DepartmentColumns = toggleModal => [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row.deleted ? "danger" : "success"}
          pill
        >
          {row.deleted ? "Deleted" : "Active"}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, department) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleDepartmentDetails(department)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, department) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleDepartmentClick(department)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(department)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setDepartment(null)
    } else {
      setModal(true)
    }
  }

  const handleDepartmentClick = arg => {
    const department = arg

    setDepartment({
      _id: department._id,
      name: department.name,
      description: department.description,
      company: department.company,
      deleted: department.deleted,
      badgeclass: department.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDepartmentDetails = arg => {
    const department = arg

    setDepartment({
      _id: department._id,
      name: department.name,
      description: department.description,
      company: department.company,
      companyName: department.companyName,
      deleted: department.deleted,
      badgeclass: department.badgeclass,
    })
    console.log("BUSINESS: ", department)

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const onClickDelete = department => {
    setDepartment(department)
    setDeleteModal(true)
  }

  const handleDeleteDepartment = () => {
    if (department._id) {
      dispatch(onDeleteDepartment(department))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }
  const handleDepartmentClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "_id",
      department: "desc",
    },
  ]

  useEffect(() => {
    if (departments && !departments.length) {
      dispatch(onGetDepartments())
    }
  }, [dispatch, departments])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (companies) {
      const activeCompanies = companies.filter(company => !company.deleted)
      setCompanyList(activeCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (companies && departments) {
      const newDepartments = departments
        .filter(department => !department.deleted)
        .map(department => ({
          ...department,
          companyName: companies.find(
            company => company._id === department.company
          )?.name,
        }))
      setDepartmentList(newDepartments)
    }
  }, [departments, companies])

  return (
    <React.Fragment>
      <DepartmentDetailsModal
        isOpen={modal1}
        toggle={toggleViewModal}
        department={department}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDepartment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Departments</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Departments" breadcrumbItem="Departments" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={DepartmentColumns(toggle)}
                    data={departmentList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={departmentList}
                        columns={DepartmentColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleDepartmentClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Department
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bdepartmented={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit Department"
                                      : "Add Department"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Department Name
                                            </Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.name || ""
                                              }
                                              invalid={
                                                validation.touched.name &&
                                                validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name &&
                                            validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Department Description
                                            </Label>
                                            <Input
                                              name="description"
                                              type="textarea"
                                              id="textarea"
                                              maxLength="225"
                                              rows="3"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.description ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched
                                                  .description &&
                                                validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.description &&
                                            validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          {!isEdit && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Company
                                              </Label>
                                              <Input
                                                name="company"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.company ||
                                                  ""
                                                }
                                              >
                                                {companyList.map(
                                                  (company, index) => (
                                                    <option
                                                      value={company._id}
                                                      key={company._id}
                                                    >
                                                      {company.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Departments.propTypes = {
  departments: PropTypes.array,
  onGetDepartments: PropTypes.func,
  onAddNewDepartment: PropTypes.func,
  onDeleteDepartment: PropTypes.func,
  onUpdateDepartment: PropTypes.func,
}

export default withRouter(Departments)
