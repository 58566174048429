import {
  GET_OPTIONS_FAIL,
  GET_OPTIONS_SUCCESS,
  ADD_OPTION_SUCCESS,
  ADD_OPTION_FAIL,
  UPDATE_OPTION_SUCCESS,
  UPDATE_OPTION_FAIL,
  DELETE_OPTION_SUCCESS,
  DELETE_OPTION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  options: [],
}

const Options = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload,
      }

    case GET_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_OPTION_SUCCESS:
      return {
        ...state,
        options: [...state.options, action.payload],
      }

    case ADD_OPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_OPTION_SUCCESS:
      return {
        ...state,
        options: state.options.map(option =>
          option._id === action.payload._id.toString()
            ? { option, ...action.payload }
            : option
        ),
      }

    case UPDATE_OPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_OPTION_SUCCESS:
      return {
        ...state,
        options: state.options.filter(
          option => option._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_OPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Options
