import React from "react"
import { Redirect } from "react-router-dom"

// //Companies
import Companies from "../pages/Companies/index"

// //Businesses
import Businesses from "../pages/Businesses/index"

// //Regions
import Regions from "../pages/Regions/index"

// //Stores
import Stores from "../pages/Stores/index"

// //Departments
import Departments from "../pages/Departments/index"

// //Company Users
import CompanyUsers from "../pages/CompanyUsers/index"

// //Terminals
import Terminals from "../pages/Terminals/index"

// //Options
import Options from "../pages/Options/index"

// //Profiles
import Profiles from "../pages/Profiles/index"

// //EButtons
import EButtons from "../pages/EButtons/index"

// //EButtons
import Devices from "../pages/Devices/index"

// Authentication related pages
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login2 from "../pages/AuthenticationInner/Login2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

const authProtectedRoutes = [
  //Companies
  { path: "/companies", component: Companies },

  //Businesses
  { path: "/businesses", component: Businesses },

  //Regions
  { path: "/regions", component: Regions },

  //Stores
  { path: "/stores", component: Stores },

  //Departments
  { path: "/departments", component: Departments },

  //Company Users
  { path: "/company-users", component: CompanyUsers },

  //Terminals
  { path: "/terminals", component: Terminals },

  //Profiles
  { path: "/profiles", component: Profiles },

  //EButtons
  { path: "/e-buttons", component: EButtons },

  //Options
  { path: "/options", component: Options },

  //Device
  { path: "/devices", component: Devices },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/companies" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login2 },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/auth-two-step-verification", component: TwostepVerification },
]

export { authProtectedRoutes, publicRoutes }
