import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_STORES,
  ADD_NEW_STORE,
  DELETE_STORE,
  UPDATE_STORE,
} from "./actionTypes"
import {
  getStoresFail,
  getStoresSuccess,
  addStoreFail,
  addStoreSuccess,
  updateStoreSuccess,
  updateStoreFail,
  deleteStoreSuccess,
  deleteStoreFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStores,
  addNewStore,
  updateStore,
  deleteStore,
} from "helpers/backend_helper"

function* fetchStores() {
  try {
    const response = yield call(getStores)
    yield put(getStoresSuccess(response))
  } catch (error) {
    yield put(getStoresFail(error))
  }
}

function* onUpdateStore({ payload }) {
  try {
    const response = yield call(updateStore, payload)
    yield put(updateStoreSuccess(response.data))
  } catch (error) {
    yield put(updateStoreFail(error))
  }
}

function* onDeleteStore({ payload }) {
  try {
    const response = yield call(deleteStore, payload)
    console.log("response", response)
    yield put(deleteStoreSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteStoreFail(error))
  }
}

function* onAddNewStore({ payload: store }) {
  try {
    const response = yield call(addNewStore, store)
    yield put(addStoreSuccess(response.data))
  } catch (error) {
    yield put(addStoreFail(error))
  }
}

function* storesSaga() {
  yield takeEvery(GET_STORES, fetchStores)
  yield takeEvery(ADD_NEW_STORE, onAddNewStore)
  yield takeEvery(UPDATE_STORE, onUpdateStore)
  yield takeEvery(DELETE_STORE, onDeleteStore)
}

export default storesSaga
