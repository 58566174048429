import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_BUSINESSES,
  ADD_NEW_BUSINESS,
  DELETE_BUSINESS,
  UPDATE_BUSINESS,
} from "./actionTypes"
import {
  getBusinessesFail,
  getBusinessesSuccess,
  addBusinessFail,
  addBusinessSuccess,
  updateBusinessSuccess,
  updateBusinessFail,
  deleteBusinessSuccess,
  deleteBusinessFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBusinesses,
  addNewBusiness,
  updateBusiness,
  deleteBusiness,
} from "helpers/backend_helper"

function* fetchBusinesses() {
  try {
    const response = yield call(getBusinesses)
    yield put(getBusinessesSuccess(response))
  } catch (error) {
    yield put(getBusinessesFail(error))
  }
}

function* onUpdateBusiness({ payload }) {
  try {
    const response = yield call(updateBusiness, payload)
    yield put(updateBusinessSuccess(response.data))
  } catch (error) {
    yield put(updateBusinessFail(error))
  }
}

function* onDeleteBusiness({ payload }) {
  try {
    const response = yield call(deleteBusiness, payload)
    console.log("response", response)
    yield put(deleteBusinessSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteBusinessFail(error))
  }
}

function* onAddNewBusiness({ payload: business }) {
  try {
    const response = yield call(addNewBusiness, business)
    yield put(addBusinessSuccess(response.data))
  } catch (error) {
    yield put(addBusinessFail(error))
  }
}

function* businessesSaga() {
  yield takeEvery(GET_BUSINESSES, fetchBusinesses)
  yield takeEvery(ADD_NEW_BUSINESS, onAddNewBusiness)
  yield takeEvery(UPDATE_BUSINESS, onUpdateBusiness)
  yield takeEvery(DELETE_BUSINESS, onDeleteBusiness)
}

export default businessesSaga
