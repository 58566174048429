import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const RegionDetailsModal = props => {
  const { isOpen, toggle, region } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Region Details</ModalHeader>
        <ModalBody>
          <h6 className="mb-2">
            Region id: <span className="text-primary">#{region?._id}</span>
          </h6>
          <h6 className="mb-4">
            Name: <span className="text-primary">{region?.name}</span>
          </h6>
          <p className="mb-2">
            Description:{" "}
            <span className="text-primary">{region?.description}</span>
          </p>
          <p className="mb-2">
            Company: <span className="text-primary">{region?.companyName}</span>
          </p>
          <p className="mb-2">
            Business:{" "}
            <span className="text-primary">{region?.businessName}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

RegionDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  region: PropTypes.object,
}

export default RegionDetailsModal
