import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import toastr from "toastr"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { FieldArray, useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getCompanyUsers as onGetCompanyUsers,
  getProfiles as onGetProfiles,
  getCompanies as onGetCompanies,
  getBusinesses as onGetBusinesses,
  getRegions as onGetRegions,
  getStores as onGetStores,
  getDepartments as onGetDepartments,
  addNewCompanyUser as onAddNewCompanyUser,
  updateCompanyUser as onUpdateCompanyUser,
  deleteCompanyUser as onDeleteCompanyUser,
} from "store/actions"
import CompanyUserDetailsModal from "./CompanyUserDetailsModal"
import Select from "react-select"
import { MultiSelect } from "../../components/Common/MultiSelect"

const CompanyUsers = props => {
  const dispatch = useDispatch()
  const isFirstTimeLoading = useRef(true)
  const [companyUserList, setCompanyUserList] = useState([])
  const [profileList, setProfileList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [storeList, setStoreList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [departmentListFilter, setDepartmentListFilter] = useState([])
  const [companyUser, setCompanyUser] = useState(null)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  //delete companyUser
  const [deleteModal, setDeleteModal] = useState(false)

  const { companyUsers } = useSelector(state => ({
    companyUsers: state.companyUsers.companyUsers,
  }))

  const { profiles } = useSelector(state => ({
    profiles: state.profiles.profiles,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  const { businesses } = useSelector(state => ({
    businesses: state.businesses.businesses,
  }))

  const { regions } = useSelector(state => ({
    regions: state.regions.regions,
  }))

  const { stores } = useSelector(state => ({
    stores: state.stores.stores,
  }))

  const { departments } = useSelector(state => ({
    departments: state.departments.departments,
  }))

  const {error} = useSelector(state => ({
    error: state.companyUsers.error,
  }))

  useEffect(() => {
    if(error) {
      toastr.error(error?.response?.data?.message, "Error when sending")
    } else {
      validation.resetForm()
      setModal(false)
      setCompanyUser(null)
      dispatch(onGetCompanyUsers())
    }
  }, [error])

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: (companyUser && companyUser?._id) || "",
      firstname: (companyUser && companyUser?.firstname) || "",
      lastname: (companyUser && companyUser?.lastname) || "",
      title: (companyUser && companyUser?.title) || "",
      email: (companyUser && companyUser?.email) || "",
      username: (companyUser && companyUser?.username) || "",
      password: "",
      passwordConfirm: "",
      phone: (companyUser && companyUser?.phone) || "",
      videoLink: (companyUser && companyUser?.videoLink) || 'false',
      admin_user: (companyUser && companyUser?.admin_user) || 'false',
      company: (companyUser && companyUser?.company) || "",
      profile:
        companyUser && companyUser?.profile && isEdit ? companyUser?.profile : "",
      businesses:
        companyUser && companyUser?.businesses && isEdit
          ? companyUser?.businesses
          : [],
      regions:
        companyUser && companyUser?.regions && isEdit ? companyUser?.regions : [],
      stores:
        companyUser && companyUser?.stores && isEdit ? companyUser?.stores : [],
      departments:
        companyUser && companyUser?.departments && isEdit
          ? companyUser?.departments
          : [],
      deleted: (companyUser && companyUser?.deleted) || false,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your Company User's Name"),
      lastname: Yup.string().required(
        "Please Enter Your Company User's Last Name"
      ),
      title: Yup.string(),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      username: Yup.string().required("Please Enter Your Company User's Username"),
      password: Yup.string(),
      passwordConfirm: Yup.string().oneOf([Yup.ref('password'), 'Passwords must match'], 'Passwords must match'),
      phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
      videoLink: Yup.string(),
      admin_user: Yup.string(),
      company: Yup.string().required("Please Enter Your Company"),
      profile: Yup.string(),
      businesses: Yup.array().min(1, "Businesses is required").required("required"),
      regions: Yup.array().min(1, "Regions is required").required("required"),
      stores: Yup.array().min(1, "Stores is required").required("required"),
      departments: Yup.array(),
    }),
    onSubmit: values => {
      if (isEdit) {
        console.log("EDITING USER: ", values)
        let updateCompanyUser = {
          _id: companyUser?._id,
          firstname: values.firstname,
          lastname: values.lastname,
          title: values.title,
          videoLink: values.videoLink,
          admin_user: values.admin_user,
          phone: values.phone,
        }
        console.log("Updated USER: ", updateCompanyUser)

        // update companyUser
        // dispatch(onUpdateCompanyUser(updateCompanyUser))
      } else {
        let newCompanyUser = {
          firstname: values["firstname"],
          lastname: values["lastname"],
          title: values["title"],
          email: values["email"],
          username: values["username"],
          phone: values["phone"],
          videoLink: values["videoLink"],
          admin_user: values["admin_user"],
          company: values["company"],
          password: values["password"],
          businesses: values["businesses"].map(business => business.value),
          regions: values["regions"].map(region => region.value),
          stores: values["stores"].map(store => store.value),
          departments: values["departments"].map(
            department => department.value
          ),
          deleted: false,
        }
        if (values["profile"]) newCompanyUser = {...newCompanyUser , role: values["profile"]}
        console.log("CREATING USER: ", values)
        // save new companyUser
        dispatch(onAddNewCompanyUser(newCompanyUser))
      }
    },
  })

  const selectRow = {
    mode: "checkbox",
  }

  const { values: formValues } = validation

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: companyUsers.length, // replace later with size(companyUsers),
    custom: true,
  }
  const { SearchBar } = Search

  const CompanyUserColumns = toggleModal => [
    {
      dataField: "firstname",
      text: "Name",
      sort: true,
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "profileName",
      text: "Profile Name",
      sort: true,
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row?.deleted ? "danger" : "success"}
          pill
        >
          {row?.deleted ? "Deleted" : "Active"}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, companyUser) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleCompanyUserDetails(companyUser)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, companyUser) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleCompanyUserClick(companyUser)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(companyUser)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCompanyUser(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modal1) {
      setModal1(false)
      setCompanyUser(null)
    } else {
      setModal1(true)
    }
  }

  const handleCompanyUserClick = arg => {
    const newCompanyUser = arg

    setCompanyUser({
      _id: newCompanyUser?._id,
      firstname: newCompanyUser?.firstname,
      lastname: newCompanyUser?.lastname,
      title: newCompanyUser?.title,
      email: newCompanyUser?.email,
      username: newCompanyUser?.username,
      phone: newCompanyUser?.phone,
      videoLink: newCompanyUser?.videoLink,
      admin_user: newCompanyUser?.admin_user,
      company: newCompanyUser?.company,
      companyName: newCompanyUser?.companyName,
      profile: newCompanyUser?.role,
      profileName: newCompanyUser?.profileName,
      businesses: newCompanyUser?.businessesParse,
      regions: newCompanyUser?.regionsParse,
      stores: newCompanyUser?.storesParse,
      departments: newCompanyUser?.departmentsParse,
      deleted: newCompanyUser?.deleted,
      badgeclass: newCompanyUser?.badgeclass,
    })
    // console.log("CompanyUser: ", newCompanyUser)
    setIsEdit(true)

    toggle()
  }

  const handleCompanyUserDetails = arg => {
    const newCompanyUser = arg

    setCompanyUser({
      _id: newCompanyUser?._id,
      firstname: newCompanyUser?.firstname,
      lastname: newCompanyUser?.lastname,
      title: newCompanyUser?.title,
      email: newCompanyUser?.email,
      username: newCompanyUser?.username,
      phone: newCompanyUser?.phone,
      videoLink: newCompanyUser?.videoLink,
      admin_user: newCompanyUser?.admin_user,
      company: newCompanyUser?.company,
      companyName: newCompanyUser?.companyName,
      profile: newCompanyUser?.profile,
      profileName: newCompanyUser?.profileName,
      businesses: newCompanyUser?.businessesParse,
      regions: newCompanyUser?.regionsParse,
      stores: newCompanyUser?.storesParse,
      departments: newCompanyUser?.departmentsParse,
      deleted: newCompanyUser?.deleted,
      badgeclass: newCompanyUser?.badgeclass,
    })
    console.log("CompanyUser: ", newCompanyUser)

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const onClickDelete = companyUser => {
    setCompanyUser(companyUser)
    setDeleteModal(true)
  }

  const handleDeleteCompanyUser = () => {
    if (companyUser?._id) {
      dispatch(onDeleteCompanyUser(companyUser))
      onPaginationPageChange(1)
      setDeleteModal(false)
      setCompanyUser(null)
    }
  }
  const handleCompanyUserClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const handleFilterDepartments = (e) => {
    validation.setFieldValue("departments",[])
    setDepartmentListFilter(departmentList.filter(item => item.company == e.target?.value))
  }

  const defaultSorted = [
    {
      dataField: "_id",
      companyUser: "desc",
    },
  ]

  useEffect(() => {
    if (companyUsers && isFirstTimeLoading.current) {
      isFirstTimeLoading.current = false
      dispatch(onGetCompanyUsers())
    }
  }, [dispatch, companyUsers])

  useEffect(() => {
    if (profiles && !profiles.length) {
      dispatch(onGetProfiles())
    }
  }, [dispatch, profiles])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (businesses && !businesses.length) {
      dispatch(onGetBusinesses())
    }
  }, [dispatch, businesses])

  useEffect(() => {
    if (regions && !regions.length) {
      dispatch(onGetRegions())
    }
  }, [dispatch, regions])

  useEffect(() => {
    if (stores && !stores.length) {
      dispatch(onGetStores())
    }
  }, [dispatch, stores])

  useEffect(() => {
    if (departments && !departments.length) {
      dispatch(onGetDepartments())
    }
  }, [dispatch, departments])

  useEffect(() => {
    if (companies) {
      const activeCompanies = companies.filter(company => !company?.deleted)
      setCompanyList(activeCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (profiles && formValues.company) {
      const activeProfiles = profiles.filter(
        profile => !profile?.deleted && profile.company === formValues.company
      )
      setProfileList(activeProfiles)
    }
  }, [profiles, formValues.company])

  useEffect(() => {
    if (businesses && formValues.profile) {
      const activeBusinesses = businesses
        .filter(
          business =>
            !business?.deleted && business.company === formValues.company
        )
        .map(business => ({ value: business._id, label: business.name }))
      setBusinessList(activeBusinesses)
    }
  }, [businesses, formValues.profile])

  useEffect(() => {
    if (regions && formValues.businesses) {
      const activeRegions = regions
        .filter(
          region =>
            !region?.deleted &&
            region?.business ===
              formValues.businesses.find(
                currentBusiness => currentBusiness?.value === region?.business
              )?.value
        )
        .map(region => ({ value: region?._id, label: region?.name }))
      setRegionList(activeRegions)
    }
  }, [regions, formValues.businesses])

  useEffect(() => {
    if (stores && formValues.regions) {
      const activeStores = stores
        .filter(
          store =>
            !store?.deleted &&
            store.region ===
              formValues.regions.find(
                currentBusiness => currentBusiness.value === store.region
              )?.value
        )
        .map(store => ({ value: store._id, label: store.name }))

      setStoreList(activeStores)
    }
  }, [stores, formValues.regions])

  useEffect(() => {
    if (departments && formValues.stores) {
      const activeDepartments = departments
        .filter(
          department =>
            !department?.deleted &&
            department.store ===
              formValues.stores.find(
                currentBusiness => currentBusiness.value === department.store
              )?.value
        )
        .map(department => ({ value: department._id, label: department.name, company: department.company }))
      setDepartmentList(activeDepartments)
    }
  }, [departments, formValues.stores])

  useEffect(() => {
    if (
      companyUsers &&
      companies &&
      businesses &&
      regions &&
      stores &&
      departments &&
      profiles
    ) {
      const newCompanyUsers = companyUsers
        .filter(companyUser => !companyUser?.deleted)
        .map(companyUser => ({
          ...companyUser,
          companyName: companies.find(
            company => company._id === companyUser?.company
          )?.name,
          profileName: profiles.find(
            profile => profile?._id === companyUser?.role
          )?.name,
          businessesParse: companyUser?.profile?.businesses?.map(business => ({
            value: business?.id,
            label: business?.name,
          })),
          regionsParse: companyUser?.profile?.regions?.map(region => ({
            value: region?._id,
            label: region?.name,
          })),
          storesParse: companyUser?.profile?.stores.map(store => ({
            value: store?._id,
            label: store?.name,
          })),
          departmentsParse: companyUser?.profile?.departments?.map(department => ({
            value: department?._id,
            label: departments?.name,
          })),
        }))
      setCompanyUserList(newCompanyUsers)
    }
  }, [
    businesses,
    profiles,
    companies,
    regions,
    companyUsers,
    stores,
    departments,
  ])

  // useEffect(() => {
  //   if (
  //     companyUsers &&
  //     companies &&
  //     businesses &&
  //     regions &&
  //     stores &&
  //     departments &&
  //     profiles
  //   ) {
  //     const newCompanyUsers = companyUsers
  //       .filter(companyUser => !companyUser?.deleted)
  //       .map(companyUser => ({
  //         ...companyUser,
  //         profileName: profiles.find(
  //           profile => profile._id === companyUser?.profile
  //         )?.name,
  //         businessesParse: companyUser?.businesses.map(businessId => ({
  //           value: businessId,
  //           label: businesses.find(business => business._id === businessId)
  //             ?.name,
  //         })),
  //         regionName: regions.find(region => region._id === companyUser?.region)
  //           ?.name,
  //         storeName: stores.find(store => store._id === companyUser?.store)
  //           ?.name,
  //         departmentName: departments.find(
  //           department => department._id === companyUser?.department
  //         )?.name,
  //       }))
  //     setCompanyUserList(newCompanyUsers)
  //   }
  // }, [
  //   businesses,
  //   profiles,
  //   companies,
  //   regions,
  //   companyUsers,
  //   stores,
  //   departments,
  // ])

  return (
    <React.Fragment>
      <CompanyUserDetailsModal
        isOpen={modal1}
        toggle={toggleViewModal}
        companyUser={companyUser}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCompanyUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Users</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="CompanyUsers" breadcrumbItem="Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={CompanyUserColumns(toggle)}
                    data={companyUserList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={companyUserList}
                        columns={CompanyUserColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleCompanyUserClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add User
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bcompanyUsered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal size="lg" isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit Company User"
                                      : "Add Company User"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      <Container>
                                        <Row>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                First Name
                                              </Label>
                                              <Input
                                                name="firstname"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.firstname ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched
                                                    .firstname &&
                                                  validation.errors.firstname
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.errors.firstname ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.firstname}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Last Name
                                              </Label>
                                              <Input
                                                name="lastname"
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.lastname ||
                                                  ""
                                                }
                                                invalid={
                                                  validation.touched.lastname &&
                                                  validation.errors.lastname
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.errors.lastname ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.lastname}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Title
                                              </Label>
                                              <Input
                                                name="title"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.title || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.title &&
                                                  validation.errors.title
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.errors.title ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.title}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Email
                                              </Label>
                                              <Input
                                                name="email"
                                                type="email"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.email || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.email &&
                                                  validation.errors.email
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.errors.email ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.email}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Username
                                              </Label>
                                              <Input
                                                name="username"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.username ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.username &&
                                                  validation.errors.username
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.errors.username ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.username}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Phone
                                              </Label>
                                              <Input
                                                name="phone"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.phone || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.phone &&
                                                  validation.errors.phone
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.errors.phone ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.phone}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Password
                                              </Label>
                                              <Input
                                                name="password"
                                                type="password"
                                                autoComplete="on"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.password || ""
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Confirm Password
                                              </Label>
                                              <Input
                                                name="passwordConfirm"
                                                type="password"
                                                autoComplete="on"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.passwordConfirm || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched
                                                    .passwordConfirm &&
                                                  validation.errors.passwordConfirm
                                                    ? true
                                                    : false
                                                }
                                              />
                                                {validation.errors.passwordConfirm ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.passwordConfirm}
                                                  </FormFeedback>
                                                ) : null}
                                            </div>
                                          </Col>

                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                e-Buzz Video Access
                                              </Label>
                                              <Input
                                                name="videoLink"
                                                type="select"
                                                className="form-select"
                                                onChange={e => {
                                                  validation.handleChange(e);
                                                }
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.videoLink ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.videoLink &&
                                                  validation.errors.videoLink
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value={true} >
                                                  Enabled
                                                </option>
                                                <option value={false} >
                                                  Disabled
                                                </option>
                                              </Input>
                                              {validation.errors.videoLink ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.videoLink}
                                                </FormFeedback>
                                              ) : null}
                                            </div>  
                                          </Col>

                                          <Col xs="6">
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                User Admin
                                              </Label>
                                              <Input
                                                name="admin_user"
                                                type="select"
                                                className="form-select"
                                                onChange={e => {
                                                  validation.handleChange(e);
                                                }
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.admin_user ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.admin_user &&
                                                  validation.errors.admin_user
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value={true}>
                                                  Enabled
                                                </option>
                                                <option value={false}>
                                                  Disabled
                                                </option>
                                              </Input>
                                              {validation.errors.admin_user ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.admin_user}
                                                </FormFeedback>
                                              ) : null}
                                            </div>  
                                          </Col>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Company
                                            </Label>
                                            <Input
                                              name="company"
                                              type="select"
                                              className="form-select"
                                              onChange={e => {
                                                validation.handleChange(e);
                                                handleFilterDepartments(e);
                                               }
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.company ||
                                                ""
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                              invalid={
                                                validation.touched.company &&
                                                validation.errors.company
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="" key="none">
                                                ...Select a company
                                              </option>
                                              {companyList.map(
                                                (company, index) => (
                                                  <option
                                                    value={company._id}
                                                    key={company._id}
                                                  >
                                                    {company.name}
                                                  </option>
                                                )
                                              )}
                                            </Input>
                                            {validation.errors.company ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.company}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Profile
                                            </Label>
                                            <Input
                                              name="profile"
                                              type="select"
                                              className="form-select"
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.profile ||
                                                ""
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                              invalid={
                                                validation.touched.profile &&
                                                validation.errors.profile
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="" key="none">
                                                ...Select a profile
                                              </option>
                                              {profileList.map(
                                                (profile, index) => (
                                                  <option
                                                    value={profile._id}
                                                    key={profile._id}
                                                  >
                                                    {profile.name}
                                                  </option>
                                                )
                                              )}
                                            </Input>
                                            {validation.errors.profile ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.profile}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Businesses"
                                              name="businesses"
                                              value={
                                                validation.values.businesses
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "businesses",
                                                  newValues
                                                )
                                              }
                                              error={validation.touched.businesses && validation.errors.businesses}
                                              options={businessList}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Regions"
                                              name="regions"
                                              value={
                                                validation.values.regions
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "regions",
                                                  newValues
                                                )
                                              }
                                              error={validation.touched.regions && validation.errors.regions}
                                              options={regionList}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Stores"
                                              name="stores"
                                              value={validation.values.stores}
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "stores",
                                                  newValues
                                                )
                                              }
                                              error={validation.touched.stores && validation.errors.stores}
                                              options={storeList}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Departments"
                                              name="departments"
                                              value={
                                                validation.values.departments
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "departments",
                                                  newValues
                                                )
                                              }
                                              options={departmentListFilter}
                                            />
                                          </div>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-companyUser"
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Container>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CompanyUsers.propTypes = {
  companyUsers: PropTypes.array,
  onGetCompanyUsers: PropTypes.func,
  onAddNewCompanyUser: PropTypes.func,
  onDeleteCompanyUser: PropTypes.func,
  onUpdateCompanyUser: PropTypes.func,
}

export default withRouter(CompanyUsers)
