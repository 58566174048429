import React, { useEffect, useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"

const ErroStyle = {
  marginTop: "0.25rem",
  fontSize: "80%",
  color: "#f46a6a"
}

export const MultiSelect = ({
  label,
  name,
  value,
  onChange,
  options,
  multi = true,
  isDisabled = false,
  error,
  ...props
}) => {
  return (
    <>
      <label className="control-label">{label}</label>
      <Select
        name={name}
        value={value}
        isMulti={multi}
        isDisabled={isDisabled}
        onChange={onChange}
        options={options}
        classNamePrefix="select2-selection"
        {...props}
      />
      {error && (
        <span style={ErroStyle}>
          {error}
        </span>
      )}
    </>
  )
}

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  multi: PropTypes.boolean,
  isDisabled: PropTypes.boolean,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
}
