//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const LOGIN_USER = "/auth"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//COMPANIES
export const GET_COMPANIES = "/companies"
export const ADD_NEW_COMPANY = "/companies"
export const UPDATE_COMPANY = "/companies"
export const DELETE_COMPANY = "/companies"

//BUSINESSES
export const GET_BUSINESSES = "/businesses"
export const ADD_NEW_BUSINESS = "/businesses"
export const UPDATE_BUSINESS = "/businesses"
export const DELETE_BUSINESS = "/businesses"

//REGIONS
export const GET_REGIONS = "/regions"
export const ADD_NEW_REGION = "/regions"
export const UPDATE_REGION = "/regions"
export const DELETE_REGION = "/regions"

//STORES
export const GET_STORES = "/stores"
export const ADD_NEW_STORE = "/stores"
export const UPDATE_STORE = "/stores"
export const DELETE_STORE = "/stores"

//DEPARTMENTS
export const GET_DEPARTMENTS = "/departments"
export const ADD_NEW_DEPARTMENT = "/departments"
export const UPDATE_DEPARTMENT = "/departments"
export const DELETE_DEPARTMENT = "/departments"

//COMPANY_USERS
export const GET_COMPANY_USERS = "/company-users"
export const ADD_NEW_COMPANY_USER = "/company-users"
export const UPDATE_COMPANY_USER = "/company-users"
export const DELETE_COMPANY_USER = "/company-users"

//PROFILES
export const GET_PROFILES = "/profiles"
export const ADD_NEW_PROFILE = "/profiles"
export const UPDATE_PROFILE = "/profiles"
export const DELETE_PROFILE = "/profiles"

//EBUTTONS
export const GET_EBUTTONS = "/buttons"
export const ADD_NEW_EBUTTON = "/buttons"
export const UPDATE_EBUTTON = "/buttons"
export const DELETE_EBUTTON = "/buttons"

//DEVICES
export const GET_DEVICES = "/devices"
export const ADD_NEW_DEVICE = "/devices"
export const UPDATE_DEVICE = "/devices"
export const DELETE_DEVICE = "/devices"

//TERMINALS
export const GET_TERMINALS = "/terminals"
export const ADD_NEW_TERMINAL = "/terminals"
export const UPDATE_TERMINAL = "/terminals"
export const DELETE_TERMINAL = "/terminals"

//OPTIONS
export const GET_OPTIONS = "/options"
export const ADD_NEW_OPTION = "/options"
export const UPDATE_OPTION = "/options"
export const DELETE_OPTION = "/options"
