import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { FieldArray, Formik, useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getBusinesses as onGetBusinesses,
  getCompanies as onGetCompanies,
  getStores as onGetStores,
  getRegions as onGetRegions,
  getTerminals as onGetTerminals,
  addNewTerminal as onAddNewTerminal,
  updateTerminal as onUpdateTerminal,
  deleteTerminal as onDeleteTerminal,
} from "store/actions"
import TerminalDetailsModal from "./TerminalDetailsModal"

const Terminals = props => {
  const dispatch = useDispatch()
  const isFirstTimeLoading = useRef(true)

  const [terminalList, setTerminalList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [storeList, setStoreList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [terminal, setTerminal] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalViewDetails, setViewDetailsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { terminals } = useSelector(state => ({
    terminals: state.terminals.terminals,
  }))

  const { businesses } = useSelector(state => ({
    businesses: state.businesses.businesses,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  const { regions } = useSelector(state => ({
    regions: state.regions.regions,
  }))

  const { stores } = useSelector(state => ({
    stores: state.stores.stores,
  }))

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: (terminal && terminal._id) || "",
      company:
        (terminal && terminal.company) ||
        (companyList.length !== 0 && companyList[0]._id) ||
        "",
      business:
        terminal && terminal.business && isEdit ? terminal.business : "",
      region: terminal && terminal.region && isEdit ? terminal.region : "",
      store: terminal && terminal.store && isEdit ? terminal.store : "",
      register: (terminal && terminal.register) || "",
      registerLabel: (terminal && terminal.registerLabel) || "",
      ip: (terminal && terminal.ip) || "",
    },
    validationSchema: Yup.object({
      company: Yup.string().required("Please Enter Your Terminal's Company"),
      business: Yup.string().required("Please Enter Your Terminal's Business"),
      region: Yup.string().required("Please Enter Your Terminal's Region"),
      store: Yup.string().required("Please Enter Your Terminal's Store"),
      register: Yup.string().required("Please Enter Your Terminal's Register"),
      registerLabel: Yup.string().required(
        "Please Enter Your Terminal's Register Label"
      ),
      ip: Yup.string().required("Please Enter Your Terminal's Ip"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateTerminal = {
          _id: terminal._id,
          register: values.register,
          registerLabel: values.registerLabel,
          ip: values.ip,
        }
        console.log("EDITING TERMINAL: ", values)
        // update terminal
        dispatch(onUpdateTerminal(updateTerminal))
        validation.resetForm()
      } else {
        const newTerminal = {
          company: values["company"],
          business: values["business"],
          region: values["region"],
          store: values["store"],
          register: values["register"],
          registerLabel: values["registerLabel"],
          ip: values["ip"],
        }
        console.log("CREATING TERMINAL: ", values)
        // save new terminal
        dispatch(onAddNewTerminal(newTerminal))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { values: formValues } = validation

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: terminals.length, // replace later with size(terminals),
    custom: true,
  }
  const { SearchBar } = Search

  const TerminalColumns = toggleModal => [
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
    },
    {
      dataField: "businessName",
      text: "Business",
      sort: true,
    },
    {
      dataField: "register",
      text: "Register",
      sort: true,
    },
    {
      dataField: "registerLabel",
      text: "Register Label",
      sort: true,
    },
    {
      dataField: "ip",
      text: "IP",
      sort: true,
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row?.deleted ? "danger" : "success"}
          pill
        >
          {row?.deleted ? "Deleted" : "Active"}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, terminal) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleTerminalViewDetails(terminal)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, terminal) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleTerminalClick(terminal)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(terminal)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setTerminal(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modalViewDetails) {
      setViewDetailsModal(false)
      setTerminal(null)
    } else {
      setViewDetailsModal(true)
    }
  }

  const handleTerminalClick = arg => {
    const terminal = arg

    setTerminal({
      _id: terminal._id,
      deleted: terminal?.deleted,
      company: terminal.company,
      companyName: terminal.companyName,
      business: terminal.business,
      businessName: terminal.businessName,
      region: terminal.region,
      regionName: terminal.regionName,
      store: terminal.store,
      storeName: terminal.storeName,
      register: terminal.register,
      registerLabel: terminal.registerLabel,
      ip: terminal.ip,
      badgeclass: terminal.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  const handleTerminalViewDetails = arg => {
    const terminal = arg

    setTerminal({
      _id: terminal._id,
      deleted: terminal?.deleted,
      company: terminal.company,
      companyName: terminal.companyName,
      business: terminal.business,
      businessName: terminal.businessName,
      region: terminal.region,
      regionName: terminal.regionName,
      store: terminal.store,
      storeName: terminal.storeName,
      register: terminal.register,
      registerLabel: terminal.registerLabel,
      ip: terminal.ip,
      badgeclass: terminal.badgeclass,
    })

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete terminal
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = terminal => {
    setTerminal(terminal)
    setDeleteModal(true)
  }

  const handleDeleteTerminal = () => {
    if (terminal._id) {
      dispatch(onDeleteTerminal(terminal))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }
  const handleTerminalClicks = () => {
    setIsEdit(false)
    setTerminal(null)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "_id",
      terminal: "desc",
    },
  ]

  useEffect(() => {
    if (isFirstTimeLoading.current && terminals) {
      isFirstTimeLoading.current = false
      dispatch(onGetTerminals())
    }
  }, [dispatch, terminals])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (businesses && !businesses.length) {
      dispatch(onGetBusinesses())
    }
  }, [dispatch, businesses])

  useEffect(() => {
    if (regions && !regions.length) {
      dispatch(onGetRegions())
    }
  }, [dispatch, regions])

  useEffect(() => {
    if (stores && !stores.length) {
      dispatch(onGetStores())
    }
  }, [dispatch, stores])

  useEffect(() => {
    if (companies && businesses && stores && regions && terminals) {
      const newTerminals = terminals
        .filter(terminal => !terminal?.deleted)
        .map(terminal => ({
          ...terminal,
          companyName: companies.find(
            company => company._id === terminal.company
          )?.name,
          businessName: businesses.find(
            business => business._id === terminal.business
          )?.name,
          regionName: regions.find(region => region._id === terminal.region)
            ?.name,
          storeName: stores.find(store => store._id === terminal.store)?.name,
        }))
      setTerminalList(newTerminals)
    }
  }, [businesses, companies, stores, regions, terminals])

  useEffect(() => {
    if (companies) {
      const newCompanies = companies.filter(company => !company?.deleted)
      setCompanyList(newCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (businesses && formValues.company) {
      const newBusinesses = businesses.filter(
        business => !business?.deleted && business.company === formValues.company
      )
      setBusinessList(newBusinesses)
    }
  }, [businesses, formValues.company])

  useEffect(() => {
    if (regions && formValues.business) {
      const newRegions = regions.filter(
        region => !region?.deleted && region?.business === formValues.business
      )
      setRegionList(newRegions)
    }
  }, [regions, formValues.business])

  useEffect(() => {
    if (stores && formValues.region) {
      const newRegions = stores.filter(
        store => !store?.deleted && store.region === formValues.region
      )
      setStoreList(newRegions)
    }
  }, [stores, formValues.region])

  return (
    <React.Fragment>
      <TerminalDetailsModal
        isOpen={modalViewDetails}
        toggle={toggleViewModal}
        terminal={terminal}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTerminal}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Terminals</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Terminals" breadcrumbItem="Terminals" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={TerminalColumns(toggle)}
                    data={terminalList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={terminalList}
                        columns={TerminalColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleTerminalClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Terminal
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bterminaled={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit Terminal"
                                      : "Add Terminal"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Register
                                            </Label>
                                            <Input
                                              name="register"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.register || ""
                                              }
                                              invalid={
                                                validation.touched.register &&
                                                validation.errors.register
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.register &&
                                            validation.errors.register ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.register}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Register Label
                                            </Label>
                                            <Input
                                              name="registerLabel"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .registerLabel || ""
                                              }
                                              invalid={
                                                validation.touched
                                                  .registerLabel &&
                                                validation.errors.registerLabel
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.registerLabel &&
                                            validation.errors.registerLabel ? (
                                              <FormFeedback type="invalid">
                                                {
                                                  validation.errors
                                                    .registerLabel
                                                }
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              IP
                                            </Label>
                                            <Input
                                              name="ip"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.ip || ""}
                                              invalid={
                                                validation.touched.ip &&
                                                validation.errors.ip
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.ip &&
                                            validation.errors.ip ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.ip}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          {!isEdit && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Company
                                              </Label>
                                              <Input
                                                name="company"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.company ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.company &&
                                                  validation.errors.company
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {companyList.map(
                                                  (company, index) => (
                                                    <option
                                                      value={company._id}
                                                      key={company._id}
                                                    >
                                                      {company.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                              {validation.errors.company ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.company}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          )}

                                          {!isEdit &&
                                            businessList.length !== 0 && (
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Business
                                                </Label>
                                                <Input
                                                  name="business"
                                                  type="select"
                                                  className="form-select"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .business || ""
                                                  }
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  invalid={
                                                    validation.touched
                                                      .business &&
                                                    validation.errors.business
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  <option value="" key="none">
                                                    Select a business
                                                  </option>
                                                  {businessList.map(
                                                    (business, index) => (
                                                      <option
                                                        value={business._id}
                                                        key={business._id}
                                                      >
                                                        {business.name}
                                                      </option>
                                                    )
                                                  )}
                                                </Input>
                                                {validation.errors.business ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.business}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            )}
                                          {!isEdit && regionList.length > 0 && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Region
                                              </Label>
                                              <Input
                                                name="region"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.region || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.region &&
                                                  validation.errors.region
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value="" key="none">
                                                  Select a region
                                                </option>
                                                {regionList.map(
                                                  (region, index) => (
                                                    <option
                                                      value={region._id}
                                                      key={region._id}
                                                    >
                                                      {region.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                              {validation.errors.region ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.region}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          )}
                                          {!isEdit && storeList.length > 0 && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Store
                                              </Label>
                                              <Input
                                                name="store"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.store || ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.store &&
                                                  validation.errors.store
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value="" key="none">
                                                  Select a store
                                                </option>
                                                {storeList.map(
                                                  (store, index) => (
                                                    <option
                                                      value={store._id}
                                                      key={store._id}
                                                    >
                                                      {store.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                              {validation.errors.store ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.store}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Terminals.propTypes = {
  terminals: PropTypes.array,
  onGetTerminals: PropTypes.func,
  onAddNewTerminal: PropTypes.func,
  onDeleteTerminal: PropTypes.func,
  onUpdateTerminal: PropTypes.func,
}

export default withRouter(Terminals)
