import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getBusinesses as onGetBusinesses,
  getCompanies as onGetCompanies,
  getRegions as onGetRegions,
  addNewRegion as onAddNewRegion,
  updateRegion as onUpdateRegion,
  deleteRegion as onDeleteRegion,
} from "store/actions"
import RegionDetailsModal from "./RegionDetailsModal"

const Regions = props => {
  const dispatch = useDispatch()

  const [regionList, setRegionList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [region, setRegion] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalViewDetails, setViewDetailsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: (region && region._id) || "",
      name: (region && region.name) || "",
      company:
        (region && region.company) ||
        (companyList.length !== 0 && companyList[0]._id) ||
        "",
      business: region && region.business && isEdit ? region.business : "",
      description: (region && region.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Region Name"),
      company: Yup.string().required("Please Enter Your Region's Company"),
      business: Yup.string().required("Please Enter Your Region's Business"),
      description: Yup.string().required(
        "Please Enter Your Region's Description"
      ),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateRegion = {
          _id: region._id,
          name: values.name,
          description: values.description,
        }
        console.log("EDITING REGION: ", values)
        // update region
        dispatch(onUpdateRegion(updateRegion))
        validation.resetForm()
      } else {
        const newRegion = {
          name: values["name"],
          company: values["company"],
          business: values["business"],
          description: values["description"],
        }
        // save new region
        dispatch(onAddNewRegion(newRegion))
        validation.resetForm()
      }
      dispatch(onGetRegions())
      toggle()
    },
  })

  const { values: formValues } = validation

  const { regions } = useSelector(state => ({
    regions: state.regions.regions,
  }))

  const { businesses } = useSelector(state => ({
    businesses: state.businesses.businesses,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: regions.length, // replace later with size(regions),
    custom: true,
  }
  const { SearchBar } = Search

  const RegionColumns = toggleModal => [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
    },
    {
      dataField: "businessName",
      text: "Business",
      sort: true,
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row?.deleted ? "danger" : "success"}
          pill
        >
          {row?.deleted ? "Deleted" : "Active"}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, region) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleRegionViewDetails(region)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, region) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleRegionClick(region)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(region)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setRegion(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modalViewDetails) {
      setViewDetailsModal(false)
      setRegion(null)
    } else {
      setViewDetailsModal(true)
    }
  }

  const handleRegionClick = arg => {
    const region = arg

    setRegion({
      _id: region._id,
      name: region.name,
      description: region.description,
      deleted: region?.deleted,
      company: region.company,
      companyName: region.companyName,
      business: region.business,
      businessName: region.businessName,
      badgeclass: region.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  const handleRegionViewDetails = arg => {
    const region = arg

    setRegion({
      _id: region._id,
      name: region.name,
      description: region.description,
      company: region.company,
      companyName: region.companyName,
      business: region.business,
      businessName: region.businessName,
      deleted: region?.deleted,
      badgeclass: region.badgeclass,
    })

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete region
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = region => {
    setRegion(region)
    setDeleteModal(true)
  }

  const handleDeleteRegion = () => {
    if (region._id) {
      dispatch(onDeleteRegion(region))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }
  const handleRegionClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "_id",
      region: "desc",
    },
  ]

  useEffect(() => {
    if (regions && !regions.length) {
      dispatch(onGetRegions())
    }
  }, [dispatch, regions])

  useEffect(() => {
    if (businesses && !businesses.length) {
      dispatch(onGetBusinesses())
    }
  }, [dispatch, businesses])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (companies && businesses && regions) {
      const newRegions = regions
        .filter(region => !region?.deleted)
        .map(region => ({
          ...region,
          companyName: companies?.find(company => company?._id === region?.company)
            ?.name,
          businessName: businesses?.find(
            business => business?._id === region?.business
          )?.name,
        }))
      setRegionList(newRegions)
    }
  }, [businesses, companies, regions])

  useEffect(() => {
    if (companies) {
      const newCompanies = companies.filter(company => !company?.deleted)
      setCompanyList(newCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (businesses && formValues.company) {
      const newBusinesses = businesses.filter(
        business => !business?.deleted && business.company === formValues.company
      )
      setBusinessList(newBusinesses)
    }
  }, [businesses, formValues.company])

  return (
    <React.Fragment>
      <RegionDetailsModal
        isOpen={modalViewDetails}
        toggle={toggleViewModal}
        region={region}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRegion}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Regions</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Regions" breadcrumbItem="Regions" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={RegionColumns(toggle)}
                    data={regionList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={regionList}
                        columns={RegionColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleRegionClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Region
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bregioned={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Region" : "Add Region"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Region Name
                                            </Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.name || ""
                                              }
                                              invalid={
                                                validation.touched.name &&
                                                validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name &&
                                            validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Description
                                            </Label>
                                            <Input
                                              name="description"
                                              type="textarea"
                                              id="textarea"
                                              maxLength="225"
                                              rows="3"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.description ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched
                                                  .description &&
                                                validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.description &&
                                            validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          {!isEdit && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Company
                                              </Label>
                                              <Input
                                                name="company"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.company ||
                                                  ""
                                                }
                                              >
                                                {companyList.map(
                                                  (company, index) => (
                                                    <option
                                                      value={company._id}
                                                      key={company._id}
                                                    >
                                                      {company.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                            </div>
                                          )}
                                          {!isEdit && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Business
                                              </Label>
                                              <Input
                                                name="business"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.business ||
                                                  ""
                                                }
                                              >
                                                <option value="" key="none">
                                                  Select a business
                                                </option>
                                                {businessList.map(
                                                  (business, index) => (
                                                    <option
                                                      value={business._id}
                                                      key={business._id}
                                                    >
                                                      {business.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Regions.propTypes = {
  regions: PropTypes.array,
  onGetRegions: PropTypes.func,
  onAddNewRegion: PropTypes.func,
  onDeleteRegion: PropTypes.func,
  onUpdateRegion: PropTypes.func,
}

export default withRouter(Regions)
