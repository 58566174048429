import {
  GET_EBUTTONS,
  GET_EBUTTONS_FAIL,
  GET_EBUTTONS_SUCCESS,
  ADD_NEW_EBUTTON,
  ADD_EBUTTON_SUCCESS,
  ADD_EBUTTON_FAIL,
  UPDATE_EBUTTON,
  UPDATE_EBUTTON_SUCCESS,
  UPDATE_EBUTTON_FAIL,
  DELETE_EBUTTON,
  DELETE_EBUTTON_SUCCESS,
  DELETE_EBUTTON_FAIL,
} from "./actionTypes"

export const getEButtons = () => ({
  type: GET_EBUTTONS,
})

export const getEButtonsSuccess = eButtons => ({
  type: GET_EBUTTONS_SUCCESS,
  payload: eButtons,
})

export const getEButtonsFail = error => ({
  type: GET_EBUTTONS_FAIL,
  payload: error,
})

export const addNewEButton = eButtons => ({
  type: ADD_NEW_EBUTTON,
  payload: eButtons,
})

export const addEButtonSuccess = eButtons => ({
  type: ADD_EBUTTON_SUCCESS,
  payload: eButtons,
})

export const addEButtonFail = error => ({
  type: ADD_EBUTTON_FAIL,
  payload: error,
})

export const updateEButton = eButtons => {
  const { _id, ...newEButton } = eButtons
  return {
    type: UPDATE_EBUTTON,
    payload: {
      id: _id,
      eButtons: newEButton,
    },
  }
}

export const updateEButtonSuccess = eButtons => ({
  type: UPDATE_EBUTTON_SUCCESS,
  payload: eButtons,
})

export const updateEButtonFail = error => ({
  type: UPDATE_EBUTTON_FAIL,
  payload: error,
})

export const deleteEButton = eButtons => {
  const { _id, ...ebuttonWithoutId } = eButtons
  return {
    type: DELETE_EBUTTON,
    payload: {
      id: _id,
      eButtons: ebuttonWithoutId,
    },
  }
}

export const deleteEButtonSuccess = eButtons => ({
  type: DELETE_EBUTTON_SUCCESS,
  payload: eButtons,
})

export const deleteEButtonFail = error => ({
  type: DELETE_EBUTTON_FAIL,
  payload: error,
})
