/* OPTIONS */
export const GET_OPTIONS = "GET_OPTIONS"
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS"
export const GET_OPTIONS_FAIL = "GET_OPTIONS_FAIL"

/**
 * add OPTION
 */
export const ADD_NEW_OPTION = "ADD_NEW_OPTION"
export const ADD_OPTION_SUCCESS = "ADD_OPTION_SUCCESS"
export const ADD_OPTION_FAIL = "ADD_OPTION_FAIL"

/**
 * Edit OPTION
 */
export const UPDATE_OPTION = "UPDATE_OPTION"
export const UPDATE_OPTION_SUCCESS = "UPDATE_OPTION_SUCCESS"
export const UPDATE_OPTION_FAIL = "UPDATE_OPTION_FAIL"

/**
 * Delete OPTION
 */
export const DELETE_OPTION = "DELETE_OPTION"
export const DELETE_OPTION_SUCCESS = "DELETE_OPTION_SUCCESS"
export const DELETE_OPTION_FAIL = "DELETE_OPTION_FAIL"
