import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_EBUTTONS,
  ADD_NEW_EBUTTON,
  DELETE_EBUTTON,
  UPDATE_EBUTTON,
} from "./actionTypes"
import {
  getEButtonsFail,
  getEButtonsSuccess,
  addEButtonFail,
  addEButtonSuccess,
  updateEButtonSuccess,
  updateEButtonFail,
  deleteEButtonSuccess,
  deleteEButtonFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEButtons,
  addNewEButton,
  updateEButton,
  deleteEButton,
} from "helpers/backend_helper"

function* fetchEButtons() {
  try {
    const response = yield call(getEButtons)
    console.log("RESPONSE: ", response)
    yield put(getEButtonsSuccess(response))
  } catch (error) {
    yield put(getEButtonsFail(error))
  }
}

function* onUpdateEButton({ payload }) {
  try {
    const response = yield call(updateEButton, payload)
    console.log("RESPONSE: ", response)
    yield put(updateEButtonSuccess(response.data))
  } catch (error) {
    yield put(updateEButtonFail(error))
  }
}

function* onDeleteEButton({ payload }) {
  try {
    const response = yield call(deleteEButton, payload)
    console.log("response", response)
    yield put(deleteEButtonSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteEButtonFail(error))
  }
}

function* onAddNewEButton({ payload: eButton }) {
  try {
    const response = yield call(addNewEButton, eButton)
    yield put(addEButtonSuccess(response.data))
  } catch (error) {
    yield put(addEButtonFail(error))
  }
}

function* eButtonsSaga() {
  yield takeEvery(GET_EBUTTONS, fetchEButtons)
  yield takeEvery(ADD_NEW_EBUTTON, onAddNewEButton)
  yield takeEvery(UPDATE_EBUTTON, onUpdateEButton)
  yield takeEvery(DELETE_EBUTTON, onDeleteEButton)
}

export default eButtonsSaga
