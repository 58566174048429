import {
  GET_TERMINALS_FAIL,
  GET_TERMINALS_SUCCESS,
  ADD_TERMINAL_SUCCESS,
  ADD_TERMINAL_FAIL,
  UPDATE_TERMINAL_SUCCESS,
  UPDATE_TERMINAL_FAIL,
  DELETE_TERMINAL_SUCCESS,
  DELETE_TERMINAL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  terminals: [],
}

const Terminals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TERMINALS_SUCCESS:
      return {
        ...state,
        terminals: action.payload,
      }

    case GET_TERMINALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TERMINAL_SUCCESS:
      return {
        ...state,
        terminals: [...state.terminals, action.payload],
      }

    case ADD_TERMINAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TERMINAL_SUCCESS:
      return {
        ...state,
        terminals: state.terminals.map(terminal =>
          terminal._id === action.payload._id.toString()
            ? { terminal, ...action.payload }
            : terminal
        ),
      }

    case UPDATE_TERMINAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_TERMINAL_SUCCESS:
      return {
        ...state,
        terminals: state.terminals.filter(
          terminal => terminal._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_TERMINAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Terminals
