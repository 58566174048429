import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

const ProfileDetailsModal = props => {
  const { isOpen, toggle, profile } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Profile Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Profile id: <span className="text-primary">#{profile?._id}</span>
          </p>
          <p className="mb-4">
            Name: <span className="text-primary">{profile?.name}</span>
          </p>
          <p className="mb-2">
            Company:{" "}
            <span className="text-primary">{profile?.companyName}</span>
          </p>
          <p className="mb-2">
            HomePage: <span className="text-primary">{profile?.homepage}</span>
          </p>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Menus:</p>
            {profile?.menus &&
              profile.menus.map(menu => (
                <button
                  key={menu.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {menu?.name}
                </button>
              ))}
          </div>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Reports:</p>
            {profile?.reports &&
              profile.reports.map(report => (
                <button
                  key={report.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {report?.name}
                </button>
              ))}
          </div>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">e-Buzz:</p>
            {profile?.tools &&
              profile.tools.map(tool => (
                <button
                  key={tool.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {tool?.name}
                </button>
              ))}
          </div>
          <Card color="primary" className="text-white-50 mt-3">
            <CardBody>
              <CardTitle className="mb-4 text-white">Reports</CardTitle>
              <div className="d-flex flex-wrap gap-2">
                {profile?.reports &&
                  profile.reports.map(report => (
                    <Button
                      color="light"
                      className="btn btn-light btn-sm"
                      key={report.value}
                    >
                      {report?.name}
                    </Button>
                  ))}
              </div>
            </CardBody>
          </Card>
          <Card color="primary" className="text-white-50 mt-3">
            <CardBody>
              <CardTitle className="mb-4 text-white">Menus</CardTitle>
              <div className="d-flex flex-wrap gap-2">
                {profile?.menus &&
                  profile.menus.map(menu => (
                    <Button
                      color="light"
                      className="btn btn-light btn-sm"
                      key={menu.value}
                    >
                      {menu?.name}
                    </Button>
                  ))}
              </div>
            </CardBody>
          </Card>
          <Card color="primary" className="text-white-50 mt-3">
            <CardBody>
              <CardTitle className="mb-4 text-white">E-Buzz</CardTitle>
              <div className="d-flex flex-wrap gap-2">
                {profile?.tools &&
                  profile.tools.map(tool => (
                    <Button
                      color="light"
                      className="btn btn-light btn-sm"
                      key={tool.value}
                    >
                      {tool?.name}
                    </Button>
                  ))}
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

ProfileDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  profile: PropTypes.object,
}

export default ProfileDetailsModal
