import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_TERMINALS,
  ADD_NEW_TERMINAL,
  DELETE_TERMINAL,
  UPDATE_TERMINAL,
} from "./actionTypes"
import {
  getTerminalsFail,
  getTerminalsSuccess,
  addTerminalFail,
  addTerminalSuccess,
  updateTerminalSuccess,
  updateTerminalFail,
  deleteTerminalSuccess,
  deleteTerminalFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTerminals,
  addNewTerminal,
  updateTerminal,
  deleteTerminal,
} from "helpers/backend_helper"

function* fetchTerminals() {
  try {
    const response = yield call(getTerminals)
    yield put(getTerminalsSuccess(response))
  } catch (error) {
    yield put(getTerminalsFail(error))
  }
}

function* onUpdateTerminal({ payload }) {
  try {
    const response = yield call(updateTerminal, payload)
    console.log("RESPONSE: ", response)
    yield put(updateTerminalSuccess(response.data))
  } catch (error) {
    yield put(updateTerminalFail(error))
  }
}

function* onDeleteTerminal({ payload }) {
  try {
    const response = yield call(deleteTerminal, payload)
    console.log("response", response)
    yield put(deleteTerminalSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteTerminalFail(error))
  }
}

function* onAddNewTerminal({ payload: terminal }) {
  try {
    const response = yield call(addNewTerminal, terminal)
    yield put(addTerminalSuccess(response.data))
  } catch (error) {
    yield put(addTerminalFail(error))
  }
}

function* termininalsSaga() {
  yield takeEvery(GET_TERMINALS, fetchTerminals)
  yield takeEvery(ADD_NEW_TERMINAL, onAddNewTerminal)
  yield takeEvery(UPDATE_TERMINAL, onUpdateTerminal)
  yield takeEvery(DELETE_TERMINAL, onDeleteTerminal)
}

export default termininalsSaga
