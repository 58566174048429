import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const StoreDetailsModal = props => {
  const { isOpen, toggle, store } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Store Details</ModalHeader>
        <ModalBody>
          <h6 className="mb-2">
            Store id: <span className="text-primary">#{store?._id}</span>
          </h6>
          <h6 className="mb-4">
            Name: <span className="text-primary">{store?.name}</span>
          </h6>
          <p className="mb-2">
            Description:{" "}
            <span className="text-primary">{store?.description}</span>
          </p>
          <p className="mb-2">
            Company: <span className="text-primary">{store?.companyName}</span>
          </p>
          <p className="mb-2">
            Business:{" "}
            <span className="text-primary">{store?.businessName}</span>
          </p>
          <p className="mb-2">
            Region: <span className="text-primary">{store?.regionName}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

StoreDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  store: PropTypes.object,
}

export default StoreDetailsModal
