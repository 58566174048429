import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import img7 from "../../assets/images/product/img-7.png"
import img4 from "../../assets/images/product/img-4.png"

const CompanyDetailsModal = props => {
  const { isOpen, toggle, company } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Company Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Company id: <span className="text-primary">#{company?._id}</span>
          </p>
          <p className="mb-4">
            Name: <span className="text-primary">{company?.name}</span>
          </p>
          <p className="mb-4">
            Description:{" "}
            <span className="text-primary">{company?.description}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

CompanyDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  company: PropTypes.object,
}

export default CompanyDetailsModal
