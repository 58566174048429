import React, { useEffect, useState } from "react"
import CreatableSelect from "react-select/creatable"
import PropTypes from "prop-types"
import { FormFeedback, Label } from "reactstrap"

const createOption = label => ({
  label,
  value: label,
})

const components = {
  DropdownIndicator: null,
}

export const MultiTextInput = ({
  label,
  name,
  value,
  error,
  onChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("")
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : !error ? "#ddd" : "red",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : !error ? "#ddd" : "red",
      },
    }),
  }

  const handleChange = (newValue, actionMeta) => {
    //setValue(newValue)
    onChange(name, newValue)
  }

  const handleInputChange = newInputValue => {
    setInputValue(newInputValue)
  }

  const handleKeyDown = event => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        onChange(name, [...value, createOption(inputValue)])
        setInputValue("")
        event.preventDefault()
    }
  }

  return (
    <>
      <Label className="form-label">{label}</Label>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        value={value}
        styles={customStyles}
      />{" "}
      {error ? (
        <p style={{ color: "#F46A6A", fontSize: "10.5px", marginTop: "4px" }}>
          {error}
        </p>
      ) : null}
    </>
  )
}

MultiTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
}
