import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Companies
import companies from "./companies/reducer"

//Businesses
import businesses from "./businesses/reducer"

//Regions
import regions from "./regions/reducer"

//Stores
import stores from "./stores/reducer"

//Departments
import departments from "./departments/reducer"

//Users
import companyUsers from "./company-users/reducer"

//Terminals
import terminals from "./terminals/reducer"

//Options
import options from "./options/reducer"

//Profiles
import profiles from "./profiles/reducer"

//EButtons
import eButtons from "./e-buttons/reducer"

//Devices
import devices from "./devices/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  companies,
  businesses,
  regions,
  stores,
  departments,
  companyUsers,
  terminals,
  options,
  profiles,
  eButtons,
  devices,
})

export default rootReducer
