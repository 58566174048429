import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import companiesSaga from "./companies/saga"
import businessesSaga from "./businesses/saga"
import regionsSaga from "./regions/saga"
import storesSaga from "./stores/saga"
import departmentsSaga from "./departments/saga"
import companyUsersSaga from "./company-users/saga"
import terminalsSaga from "./terminals/saga"
import profilesSaga from "./profiles/saga"
import eButtonsSaga from "./e-buttons/saga"
import optionsSaga from "./options/saga"
import devicesSaga from "./devices/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(companiesSaga),
    fork(businessesSaga),
    fork(regionsSaga),
    fork(storesSaga),
    fork(departmentsSaga),
    fork(companyUsersSaga),
    fork(terminalsSaga),
    fork(profilesSaga),
    fork(optionsSaga),
    fork(eButtonsSaga),
    fork(devicesSaga),
  ])
}
