import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Input, FormFeedback, Label } from "reactstrap"

export const MultiSelectDataList = ({
  options,
  name,
  label,
  selectedOptions,
  remove,
  push,
  ...props
}) => {
  const [optionsList, setOptionsList] = useState([])

  const onChange = event => {
    const selectedOption = options.find(
      option => option.value === event.target.value
    )
    push(selectedOption)
    const newOptionsList = optionsList.map(option => {
      if (option.value === selectedOption.value) {
        return { ...option, hidden: true }
      }
      return option
    })
    setOptionsList(newOptionsList)
  }

  const onDelete = option => {
    // Set hidden false in optionsList
    const newOptionsList = optionsList.map(traverseOption => {
      if (traverseOption.value === option.value) {
        return { ...traverseOption, hidden: false }
      }
      return traverseOption
    })
    setOptionsList(newOptionsList)
    // Delete option from selectedOptions
    const newSelectedOptions = selectedOptions.filter(
      selectedOption => selectedOption.value !== option.value
    )
    remove(
      selectedOptions.findIndex(
        traverseOption => option.value === traverseOption.value
      )
    )
  }

  useEffect(() => {
    if (options && options.length) {
      setOptionsList([...options])
    }
  }, [options])

  return (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <Input
        name={name}
        type="select"
        className="form-select"
        {...props}
        onChange={onChange}
        validate={{
          required: { value: true },
        }}
        invalid={props.touched && props.errors ? true : false}
      >
        <option value="none" key="none">
          ...Please select an option
        </option>
        {optionsList
          .filter(option => !option.hidden)
          .map((option, index) => (
            <option value={option.value} key={option.value}>
              {option.name}
            </option>
          ))}
      </Input>
      {props.errors ? (
        <FormFeedback type="invalid">{props.errors}</FormFeedback>
      ) : null}
      <div className="d-flex flex-wrap gap-2 mt-3">
        {selectedOptions &&
          selectedOptions.map(option => (
            <button
              key={option.value}
              type="button"
              className="btn btn-primary  btn-label btn-sm"
            >
              <i
                className="bx bx-x label-icon"
                onClick={() => onDelete(option)}
              ></i>{" "}
              {option.name}
            </button>
          ))}
      </div>
    </div>
  )
}

MultiSelectDataList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  remove: PropTypes.func,
  push: PropTypes.func,
  touched: PropTypes.bool,
  errors: PropTypes.string,
  setTouched: PropTypes.func,
}
