export const getConstants = () => ({
  homepageList: [
    { value: "dashboard", name: "Dashboard" },
    { value: "tools", name: "Tools" },
    { value: "my_alerts", name: "My Alerts" },
    { value: "my_tasks", name: "My Tasks" },
    { value: "my_investigations", name: "My Investigations" },
  ],
  menusList: [
    { value: "dashboard", name: "Dashboard" },
    { value: "Tasks", name: "Tasks" },
    { value: "Tools", name: "Tools" },
    { value: "Reports", name: "My Reports" },
    { value: "Alerts", name: "My Alerts" },
    { value: "Investigations", name: "My Investigations" },
    { value: "Settings", name: "Settings" },
  ],
  toolsList: [
    { value: "action_buttons", name: "Action Buttons" },
    { value: "investigation", name: "Investigation" },
    { value: "person_enroll", name: "Person Enroll" },
    { value: "chat_system", name: "Chat System" },
  ],
  reportsList: [
    { value: "Traffic", name: "Traffic" },
    { value: "HourlyTraffic", name: "Hourly Traffic" },
    { value: "ConversionRate", name: "Conversion Rate" },
    { value: "EmployeePerformance", name: "Employee Performance" },
    { value: "HourlyTransactions", name: "Hourly Transactions" },
    { value: "KPIDataMining", name: "KPI Data Mining" },
    { value: "RevenueAnalysis", name: "RevenueAnalysis" },
    { value: "HourlyRevenues", name: "Hourly Revenues" },
    { value: "lpr", name: "LPR" },
    { value: "iFaces", name: "evms-cam" },
  ],
})

export const findConstant = (constantName, value) => {
  return getConstants()[constantName].find(constant => constant.value === value)
}
