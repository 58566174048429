import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const ComapanyUserDetailsModal = props => {
  const { isOpen, toggle, companyUser } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Comapany User Details</ModalHeader>
        <ModalBody>
          <h6 className="mb-2">
            Comapany User id:{" "}
            <span className="text-primary">#{companyUser?._id}</span>
          </h6>
          <h6 className="mb-2">
            First Name:{" "}
            <span className="text-primary">{companyUser?.firstname}</span>
          </h6>
          <p className="mb-2">
            Last Name:{" "}
            <span className="text-primary">{companyUser?.lastname}</span>
          </p>
          <p className="mb-2">
            Title: <span className="text-primary">{companyUser?.title}</span>
          </p>
          <p className="mb-2">
            Email: <span className="text-primary">{companyUser?.email}</span>
          </p>
          <p className="mb-2">
            Username:{" "}
            <span className="text-primary">{companyUser?.username}</span>
          </p>
          <p className="mb-2">
            Phone: <span className="text-primary">{companyUser?.phone}</span>
          </p>
          <p className="mb-2">
            Profile:{" "}
            <span className="text-primary">{companyUser?.profileName}</span>
          </p>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Businesses:</p>
            {companyUser?.businesses &&
              companyUser.businesses.map(currentValue => (
                <button
                  key={currentValue.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {currentValue.label}
                </button>
              ))}
          </div>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Regions:</p>
            {companyUser?.regions &&
              companyUser.regions.map(currentValue => (
                <button
                  key={currentValue.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {currentValue.label}
                </button>
              ))}
          </div>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Stores:</p>
            {companyUser?.stores &&
              companyUser.stores.map(currentValue => (
                <button
                  key={currentValue.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {currentValue.label}
                </button>
              ))}
          </div>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <p className="mb-2">Departments:</p>
            {companyUser?.departments &&
              companyUser.departments.map(currentValue => (
                <button
                  key={currentValue.value}
                  type="button"
                  className="btn btn-primary btn-rounded btn-sm"
                >
                  {currentValue.label}
                </button>
              ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

ComapanyUserDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  companyUser: PropTypes.object,
}

export default ComapanyUserDetailsModal
