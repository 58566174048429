import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

//Verification code package
import AuthCode from "react-auth-code-input"

import { Link, useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

// import images
import eyesonlogo from "../../assets/images/eyeson-logo.png"
import { loginUser } from "../../store/actions"

const TwostepVerification = props => {
  const dispatch = useDispatch()
  let history = useHistory()
  const [code, setCode] = useState("")
  const onChange = e => {
    setCode(e)
  }

  const onConfirmCode = () => {
    const values = props.location.state
    if (values === undefined) {
      history.push("/login")
    } else {
      const newValues = { ...props.location.state, code }
      dispatch(loginUser(newValues, props.history))
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <MetaTags>
          <title>Two Step Verification Eyeson</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <img
                  src={eyesonlogo}
                  alt=""
                  height="80"
                  className="auth-logo-dark mx-auto"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your account</h4>
                        <p className="mb-5">
                          Please enter the 6 digit code
                          {/* {<span className="font-weight-semibold">
                            example@abc.com
                          </span>} */}
                        </p>

                        <Form>
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="verification">
                                <label
                                  htmlFor="digit1-input"
                                  className="sr-only"
                                >
                                  Dight 1
                                </label>
                                <AuthCode
                                  characters={6}
                                  className="form-control form-control-lg text-center"
                                  allowedCharacters="^[0-9]"
                                  inputStyle={{
                                    width: "45px",
                                    height: "42px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    marginRight: "15px",
                                    border: "1px solid #ced4da",
                                    textTransform: "uppercase",
                                  }}
                                  onChange={e => onChange(e)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-md"
                            onClick={onConfirmCode}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Copyright © {new Date().getFullYear()} Eyeson Digital. All
                  Rights Reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TwostepVerification

TwostepVerification.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
