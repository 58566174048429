import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
// import images
import eyesonlogo from "../../assets/images/logo.jpg"
import eyesonlogin from "../../assets/images/eyeson-login.png"

const Login2 = () => {
  let history = useHistory()
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      history.push("/auth-two-step-verification", values)
    },
  })
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Login Eyeson</title>
        </MetaTags>
        <Container fluid>
          <Row className="g-0 align-middle justify-content-center">
            <Col md={2}></Col>
            <Col md={3} className="align-middle justify-content-center">
              <div className="auth-full-page-content p-md-3 p-2">
                <div className="w-100 mt-5">
                  <div className="d-flex flex-column mt-5">
                    <div className="mb-1 mb-md-3 mt-5 text-center">
                      <img
                        src={eyesonlogo}
                        alt="Eyeson Logo"
                        height="150"
                        className="auth-logo-light"
                      />
                    </div>
                    <div className="my-auto">
                      <div>
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              autoComplete="on"
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>

                        {/* {<Form action="dashboard">
                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">Sign in with</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Form>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md={7}
              className="media align-items-center justify-content-center"
            >
              <img src={eyesonlogin} width="60%" alt="Eyeson Login" />
            </Col>
            
            <Col md={12}>
              <div className="text-center">
                <p className="mb-0">
                  Copyright © {new Date().getFullYear()} Eyeson Digital.
                  All Rights Reserved
                </p>
              </div>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login2

Login2.propTypes = {
  history: PropTypes.object,
}
