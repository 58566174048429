import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [structure, setStructure] = useState(false)
  const [tools, setTools] = useState(false)

  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [project, setproject] = useState(false)
  const [task, settask] = useState(false)
  const [contact, setcontact] = useState(false)
  const [blog, setBlog] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid" style={{maxWidth: '100%'}}>
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    to="/companies"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bxs-bank me-2"></i>
                    {props.t("Companies")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/businesses"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-buildings me-2"></i>
                    {props.t("Businesses")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/regions"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-map-pin me-2"></i>
                    {props.t("Regions")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/stores"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-store-alt me-2"></i>
                    {props.t("Stores")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/departments"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-hive me-2"></i>
                    {props.t("Departments")}
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link
                    to="/terminals"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-desktop me-2"></i>
                    {props.t("Terminals")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/options"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-customize me-2"></i>
                    {props.t("Options")}
                  </Link>
                </li> */}
                <li className="nav-item dropdown">
                  <Link
                    to="/profiles"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-user-circle me-2"></i>
                    {props.t("Profiles")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/company-users"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-group me-2"></i>
                    {props.t("Users")}
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link
                    to="/e-buttons"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-analyse me-2"></i>
                    {props.t("e-Buttons")}
                  </Link>
                </li> */}
                <li className="nav-item dropdown">
                  <Link
                    to="/devices"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-devices me-2"></i>
                    {props.t("Devices")}
                  </Link>
                </li>

                {/* Group of Tools
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setTools(!tools)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone me-2"></i>
                    {props.t("Tools")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: tools })}>
                    <Link to="/terminals" className="dropdown-item">
                      {props.t("Terminals")}
                    </Link>

                    <Link to="/options" className="dropdown-item">
                      {props.t("Options")}
                    </Link>

                    <Link to="/profiles" className="dropdown-item">
                      {props.t("Profiles")}
                    </Link>

                    <Link to="/company-users" className="dropdown-item">
                      {props.t("Company Users")}
                    </Link>
                  </div>
                </li> */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
