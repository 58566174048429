import {
  GET_COMPANY_USERS_FAIL,
  GET_COMPANY_USERS_SUCCESS,
  ADD_COMPANY_USER_SUCCESS,
  ADD_COMPANY_USER_FAIL,
  UPDATE_COMPANY_USER_SUCCESS,
  UPDATE_COMPANY_USER_FAIL,
  DELETE_COMPANY_USER_SUCCESS,
  DELETE_COMPANY_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  companyUsers: [],
}

const CompanyUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_USERS_SUCCESS:
      return {
        ...state,
        companyUsers: action.payload,
      }

    case GET_COMPANY_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMPANY_USER_SUCCESS:
      return {
        ...state,
        companyUsers: [...state.companyUsers, action.payload],
      }

    case ADD_COMPANY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COMPANY_USER_SUCCESS:
      return {
        ...state,
        companyUsers: state.companyUsers.map(companyUser =>
          companyUser._id === action.payload._id.toString()
            ? { companyUser, ...action.payload }
            : companyUser
        ),
      }

    case UPDATE_COMPANY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMPANY_USER_SUCCESS:
      return {
        ...state,
        companyUsers: state.companyUsers.filter(
          companyUser =>
            companyUser._id.toString() !== action.payload._id.toString()
        ),
      }

    case DELETE_COMPANY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default CompanyUsers
