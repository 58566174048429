import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { FieldArray, Formik, FormikProvider, useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"
import { MultiSelect } from "../../components/Common/MultiSelect"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getDevices as onGetDevices,
  addNewDevice as onAddNewDevice,
  updateDevice as onUpdateDevice,
  deleteDevice as onDeleteDevice,
  getCompanies as onGetCompanies,
  getStores as onGetStores
} from "store/actions"
import DeviceDetailsModal from "./DeviceDetailsModal"

const Devices = props => {
  const dispatch = useDispatch()
  const isFirstTimeLoading = useRef(true)
  const [device, setDevice] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalViewDetails, setViewDetailsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [storeDisabled, setStoreDisabled] = useState(true)
  const [companyList, setCompanyList] = useState([])
  const [storeList, setStoreList] = useState([])

  const optionsTypes = [
    { value: 'DW', label: 'DW' },
    { value: 'Odyssey', label: 'Odyssey' },
  ]

  const optionsPreDvr = [
    { value: 'Y', label: 'Y' },
    { value: 'N', label: 'N' },
  ]

  const { devices } = useSelector(state => ({
    devices: state.devices.devices,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  const { stores } = useSelector(state => ({
    stores: state.stores.stores,
  }))

  const { SearchBar } = Search

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: (device && device._id) || "",
      evms: (device && device.evms) || "",
      description: (device && device.description) || "",
      type: (device && device.type) || "",
      systemId: (device && device.systemId) || "",
      internalIp: (device && device.internalIp) || "",
      internalPort: (device && device.internalPort) || "",
      internalUsername: (device && device.internalUsername) || "",
      internalPassword: (device && device.internalPassword) || "",
      internalPreDvr: (device && device.internalPreDvr) || "",
      externalIp: (device && device.externalIp) || "",
      externalPort: (device && device.externalPort) || "",
      externalUsername: (device && device.externalUsername) || "",
      externalPassword: (device && device.externalPassword) || "",
      externalPreDvr: (device && device.externalPreDvr) || "",
      company: (device && device.company) || "",
      store: (device && device.store) || "",
      mapping: (device && device.mapping) || [],
    },
    validationSchema: Yup.object({
      evms: Yup.string().required("Please Enter Your evms"),
      description: Yup.string().required("Please Enter Your Description"),
      systemId: Yup.string().required("Please Enter Your System Id"),
      internalIp: Yup.string().required("Please Enter Your Internal IP"),
      internalPort: Yup.string().required("Please Enter Your Internal Port"),
      internalUsername: Yup.string().required("Please Enter Your Internal Username"),
      internalPassword: Yup.string().required("Please Enter Your Internal Password"),
      externalIp: Yup.string().required("Please Enter Your External IP"),
      externalPort: Yup.string().required("Please Enter Your External Port"),
      externalUsername: Yup.string().required("Please Enter Your External Username"),
      externalPassword: Yup.string().required("Please Enter Your External Password"),
    }),
    onSubmit: values => {
      const payload = {
        evms: values.evms,
        description: values.description,
        type: values.type.value,
        systemId: values.systemId,
        internal: {
          ip: values.internalIp,
          port: values.internalPort,
          username: values.internalUsername,
          password: values.internalPassword,
          pre_dvr: values.internalPreDvr.value,
        },
        external: {
          ip: values.externalIp,
          port: values.externalPort,
          username: values.externalUsername,
          password: values.externalPassword,
          pre_dvr: values.externalPreDvr.value,
        },
        company: values.company.value,
        store: values.store.value,
        mapping: values.mapping,
      }
      if (isEdit) {
        console.log("EDITING device: ", {...payload, _id: device._id})
        dispatch(onUpdateDevice({...payload, _id: device._id}))
      } else {
        console.log("CREATING device: ", payload)
        dispatch(onAddNewDevice(payload))
      }
      validation.resetForm()
      toggle()
    },
  })

  const DevicesColumns = toggleModal => [
    {
      dataField: "evms",
      text: "Evms",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, device) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleDeviceDetails(device)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, device) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleDeviceClick(device)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(device)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setDevice(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modalViewDetails) {
      setViewDetailsModal(false)
      setDevice(null)
    } else {
      setViewDetailsModal(true)
    }
  }

  const handleDeviceClick = arg => {
    const device = arg

    let company = [];
    let store = [];
    if(device.company) {
      company = companies.filter(e => e._id == device.company)
      company = company.map(item => ({ value: item._id, label: `${item.name}` }))
      store = stores.filter(e => e.company == company[0]?.value)
      store = store.map(e => ({ value: e._id, label: `${e.name}` }))
      setStoreList(store)
      store = store.filter(e => e.value == device.store)
    }
    setDevice({
      _id: device._id,
      evms: device.evms,
      description: device.description,
      type: optionsTypes.filter(e => e.value == device.type),
      systemId: device.systemId,
      internalIp: device.internal?.ip,
      internalPort: device.internal?.port,
      internalUsername: device.internal?.username,
      internalPassword: device.internal?.password,
      internalPreDvr: optionsPreDvr.filter(e => e.value == device.internal?.pre_dvr),
      externalIp: device.external?.ip,
      externalPort: device.external?.port,
      externalUsername: device.external?.username,
      externalPassword: device.external?.password,
      externalPreDvr: optionsPreDvr.filter(e => e.value == device.external?.pre_dvr),
      company: company,
      store: store,
      mapping: device.mapping,
    })

    setIsEdit(true)
    setStoreDisabled(false)
    toggle()
  }

  const handleDeviceDetails = arg => {
    const device = arg

    setDevice({
      _id: device._id,
      evms: device.evms,
      description: device.description,
      type: device.type,
      systemId: device.systemId,
      internalIp: device.internal?.ip,
      internalPort: device.internal?.port,
      internalUsername: device.internal?.username,
      internalPassword: device.internal?.password,
      internalPreDvr: device.internal?.pre_dvr,
      externalIp: device.external?.ip,
      externalPort: device.external?.port,
      externalUsername: device.external?.username,
      externalPassword: device.external?.password,
      externalPreDvr: device.external?.pre_dvr,
      company: device.company,
      store: device.store,
      mapping: device.mapping,
    })

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const onClickDelete = device => {
    setDevice(device)
    setDeleteModal(true)
  }

  const handleDeleteDevice = () => {
    if (device._id) {
      dispatch(onDeleteDevice(device))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }

  const handleDeviceClicks = () => {
    setDevice(null)
    setIsEdit(false)
    toggle()
  }

  const handleChangeCompany = (company) => {
    const store = stores.filter(e => e.company == company.value)
    setStoreList(store.map(e => ({ value: e._id, label: `${e.name}` })))
    setStoreDisabled(false)
  }

  useEffect(() => {
    if (stores && !stores.length) {
      dispatch(onGetStores())
    }
  }, [dispatch, stores])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (companies) {
      const activeCompanies = companies.map(item => ({ value: item._id, label: `${item.name}` }))
      setCompanyList(activeCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (isFirstTimeLoading.current && devices) {
      isFirstTimeLoading.current = false
      dispatch(onGetDevices())
    }
  }, [dispatch, devices])

  return (
    <React.Fragment>
      <DeviceDetailsModal
        isOpen={modalViewDetails}
        toggle={toggleViewModal}
        device={device}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDevice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Devices</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Devices" breadcrumbItem="Devices" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory({ sizePerPage: 10, totalSize: devices.length, custom: true })}
                    keyField="id"
                    columns={DevicesColumns(toggle)}
                    data={devices}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={devices}
                        columns={DevicesColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleDeviceClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add device
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bprofileed={false}
                                    striped={false}
                                    defaultSorted={[{dataField: "_id", device: "desc"}]}
                                    selectRow={{mode: "checkbox"}}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal size="lg" isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit device" : "Add device"}
                                  </ModalHeader>
                                  <ModalBody>
                                      <FormikProvider value={validation}>

                                    <form
                                      onSubmit={validation.handleSubmit}
                                    >
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                               EVMS
                                            </Label>
                                            <Input
                                              name="evms"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.evms ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched.evms &&
                                                validation.errors.evms
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.errors.evms ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.evms}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Description
                                            </Label>
                                            <Input
                                              name="description"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.description ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched.description &&
                                                validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <MultiSelect
                                              label="Type"
                                              name="type"
                                              multi={false}
                                              value={
                                                validation.values.type
                                              }
                                              onChange={newValues =>
                                                validation.setFieldValue(
                                                  "type",
                                                  newValues
                                                )
                                              }
                                              options={optionsTypes}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              SystemId
                                            </Label>
                                            <Input
                                              name="systemId"
                                              type="text"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={
                                                validation.handleChange
                                              }
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.systemId ||
                                                ""
                                              }
                                              invalid={
                                                validation.touched.systemId &&
                                                validation.errors.systemId
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.errors.systemId ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.systemId}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                        <Col className="col-12 col-sm-12 col-md-6">
                                            <h4>Internal</h4>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Ip
                                                </Label>
                                                <Input
                                                name="internalIp"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.internalIp ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.internalIp &&
                                                    validation.errors.internalIp
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.internalIp ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.internalIp}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Port
                                                </Label>
                                                <Input
                                                name="internalPort"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.internalPort ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.internalPort &&
                                                    validation.errors.internalPort
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.internalPort ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.internalPort}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Username
                                                </Label>
                                                <Input
                                                name="internalUsername"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.internalUsername ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.internalUsername &&
                                                    validation.errors.internalUsername
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.internalUsername ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.internalUsername}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Password
                                                </Label>
                                                <Input
                                                name="internalPassword"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.internalPassword ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.internalPassword &&
                                                    validation.errors.internalPassword
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.internalPassword ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.internalPassword}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <MultiSelect
                                                  label="Pre DVR"
                                                  name="internalPreDvr"
                                                  multi={false}
                                                  value={
                                                      validation.values.internalPreDvr
                                                  }
                                                  onChange={newValues =>{
                                                      handleChangeCompany(newValues)
                                                      validation.setFieldValue(
                                                          "internalPreDvr",
                                                          newValues
                                                      )}
                                                  }
                                                  options={optionsPreDvr}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 col-sm-12 col-md-6">
                                            <h4>External</h4>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Ip
                                                </Label>
                                                <Input
                                                name="externalIp"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.externalIp ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.externalIp &&
                                                    validation.errors.externalIp
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.externalIp ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.externalIp}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Port
                                                </Label>
                                                <Input
                                                name="externalPort"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.externalPort ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.externalPort &&
                                                    validation.errors.externalPort
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.externalPort ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.externalPort}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Username
                                                </Label>
                                                <Input
                                                name="externalUsername"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.externalUsername ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.externalUsername &&
                                                    validation.errors.externalUsername
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.externalUsername ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.externalUsername}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    Password
                                                </Label>
                                                <Input
                                                name="externalPassword"
                                                type="text"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={
                                                    validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.externalPassword ||
                                                    ""
                                                }
                                                invalid={
                                                    validation.touched.externalPassword &&
                                                    validation.errors.externalPassword
                                                    ? true
                                                    : false
                                                }
                                                />
                                                {validation.errors.externalPassword ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.externalPassword}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                              <MultiSelect
                                                label="Pre DVR"
                                                name="externalPreDvr"
                                                multi={false}
                                                value={
                                                    validation.values.externalPreDvr
                                                }
                                                onChange={newValues =>{
                                                    handleChangeCompany(newValues)
                                                    validation.setFieldValue(
                                                        "externalPreDvr",
                                                        newValues
                                                    )}
                                                }
                                                options={optionsPreDvr}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12">
                                            <div className="mb-3">
                                                <MultiSelect
                                                label="Company"
                                                name="company"
                                                multi={false}
                                                value={
                                                    validation.values.company
                                                }
                                                onChange={newValues =>{
                                                    handleChangeCompany(newValues)
                                                    validation.setFieldValue(
                                                        "company",
                                                        newValues
                                                    )}
                                                }
                                                options={companyList}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <MultiSelect
                                                label="Store"
                                                name="store"
                                                multi={false}
                                                isDisabled={storeDisabled}
                                                value={
                                                    validation.values.store
                                                }
                                                onChange={newValues =>
                                                    validation.setFieldValue(
                                                        "store",
                                                        newValues
                                                    )
                                                }
                                                options={storeList}
                                                />
                                            </div>
                                            <h4>Mapping</h4>
                                            <FieldArray
                                                name="mapping"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {validation.values.mapping.map((mapping, index) => (
                                                        <Row key={index}>
                                                            <Col className="mb-3 col-5">
                                                                <Label className="form-label"> Camera Number </Label>
                                                                <Input
                                                                    name={`mapping[${index}].camera`}
                                                                    type="text"
                                                                    validate={{required: { value: true }}}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.mapping[index].camera || ""}
                                                                />
                                                            </Col>
                                                            <Col className="mb-3 col-5">
                                                                <Label className="form-label"> Camera Id </Label>
                                                                <Input
                                                                    name={`mapping[${index}].camera_id`}
                                                                    type="text"
                                                                    validate={{required: { value: true }}}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.mapping[index].camera_id || ""}
                                                                />
                                                            </Col>
                                                            <Col className="col-2" style={{marginTop: "34px",}}>
                                                                <a onClick={() => arrayHelpers.remove(index)}>
                                                                    <i className="bx bx-trash-alt me-2 font-size-24"></i>
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                        ))}
                                                        <button type="button" className="btn btn-primary save-user" onClick={() => arrayHelpers.push({ camera: "", camera_id: ""})}>
                                                            + Add Camera
                                                        </button>
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </form>
                                    </FormikProvider>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Devices.propTypes = {
  devices: PropTypes.array,
  onGetDevices: PropTypes.func,
  onAddNewDevice: PropTypes.func,
  onDeleteDevice: PropTypes.func,
  onUpdateDevice: PropTypes.func,
  onGetProfiles: PropTypes.func,
  onGetCompanyUsers: PropTypes.func,
}

export default withRouter(Devices)
