/* EBUTTONS */
export const GET_EBUTTONS = "GET_EBUTTONS"
export const GET_EBUTTONS_SUCCESS = "GET_EBUTTONS_SUCCESS"
export const GET_EBUTTONS_FAIL = "GET_EBUTTONS_FAIL"

/**
 * add EBUTTON
 */
export const ADD_NEW_EBUTTON = "ADD_NEW_EBUTTON"
export const ADD_EBUTTON_SUCCESS = "ADD_EBUTTON_SUCCESS"
export const ADD_EBUTTON_FAIL = "ADD_EBUTTON_FAIL"

/**
 * Edit EBUTTON
 */
export const UPDATE_EBUTTON = "UPDATE_EBUTTON"
export const UPDATE_EBUTTON_SUCCESS = "UPDATE_EBUTTON_SUCCESS"
export const UPDATE_EBUTTON_FAIL = "UPDATE_EBUTTON_FAIL"

/**
 * Delete EBUTTON
 */
export const DELETE_EBUTTON = "DELETE_EBUTTON"
export const DELETE_EBUTTON_SUCCESS = "DELETE_EBUTTON_SUCCESS"
export const DELETE_EBUTTON_FAIL = "DELETE_EBUTTON_FAIL"
