import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_COMPANY_USERS,
  ADD_NEW_COMPANY_USER,
  DELETE_COMPANY_USER,
  UPDATE_COMPANY_USER,
} from "./actionTypes"
import {
  getCompanyUsersFail,
  getCompanyUsersSuccess,
  addCompanyUserFail,
  addCompanyUserSuccess,
  updateCompanyUserSuccess,
  updateCompanyUserFail,
  deleteCompanyUserSuccess,
  deleteCompanyUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCompanyUsers,
  addNewCompanyUser,
  updateCompanyUser,
  deleteCompanyUser,
} from "helpers/backend_helper"

function* fetchCompanyUsers() {
  try {
    const response = yield call(getCompanyUsers)
    yield put(getCompanyUsersSuccess(response))
  } catch (error) {
    yield put(getCompanyUsersFail(error))
  }
}

function* onUpdateCompanyUser({ payload }) {
  try {
    const response = yield call(updateCompanyUser, payload)
    console.log("RESPONSE: ", response)
    yield put(updateCompanyUserSuccess(response.data))
  } catch (error) {
    yield put(updateCompanyUserFail(error))
  }
}

function* onDeleteCompanyUser({ payload }) {
  try {
    const response = yield call(deleteCompanyUser, payload)
    console.log("response", response)
    yield put(deleteCompanyUserSuccess(response.data))
  } catch (error) {
    console.log("error", error)
    yield put(deleteCompanyUserFail(error))
  }
}

function* onAddNewCompanyUser({ payload: companyUser }) {
  try {
    const response = yield call(addNewCompanyUser, companyUser)
    yield put(addCompanyUserSuccess(response.data))
    yield put(addCompanyUserFail(false))
  } catch (error) {
    yield put(addCompanyUserFail(error))
  }
}

function* companyUsersSaga() {
  yield takeEvery(GET_COMPANY_USERS, fetchCompanyUsers)
  yield takeEvery(ADD_NEW_COMPANY_USER, onAddNewCompanyUser)
  yield takeEvery(UPDATE_COMPANY_USER, onUpdateCompanyUser)
  yield takeEvery(DELETE_COMPANY_USER, onDeleteCompanyUser)
}

export default companyUsersSaga
