import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

const DeviceDetailsModal = props => {
  const { isOpen, toggle, device } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Device Details</ModalHeader>
        <ModalBody>
          <p className="mb-4">
            Evms: <span className="text-primary">{device?.evms}</span>
          </p>
          <p className="mb-4">
            Description: <span className="text-primary">{device?.description}</span>
          </p>
          <p className="mb-4">
            Type: <span className="text-primary">{device?.type}</span>
          </p>
          <p className="mb-4">
            System Id: <span className="text-primary">{device?.systemId}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

DeviceDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  device: PropTypes.object,
}

export default DeviceDetailsModal
