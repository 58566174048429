import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import CreatableSelect from "react-select/creatable"

import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getStores as onGetStores,
  getCompanies as onGetCompanies,
  getOptions as onGetOptions,
  addNewOption as onAddNewOption,
  updateOption as onUpdateOption,
  deleteOption as onDeleteOption,
} from "store/actions"
import OptionDetailsModal from "./OptionDetailsModal"
import { MultiTextInput } from "components/Common/MultiTextInput"

const Options = props => {
  const dispatch = useDispatch()
  const isFirstTimeLoading = useRef(true)
  const [optionList, setOptionList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [storeList, setStoreList] = useState([])
  const [option, setOption] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalViewDetails, setViewDetailsModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: (option && option._id) || "",
      segmentType: (option && option.segmentType) || "dropdown",
      company:
        (option && option.company) ||
        (companyList.length !== 0 && companyList[0]._id) ||
        "",
      store: isEdit && option && option.store ? option.store : "",
      values:
        (option &&
          option.values.map(currentValue => ({
            label: currentValue,
            value: currentValue,
          }))) ||
        [],
    },
    validationSchema: Yup.object({
      segmentType: Yup.string().required(
        "Please Select Your Option's Segment Type"
      ),
      company: Yup.string().required("Please Select Your Option's Company"),
      store: Yup.string().required("Please Select Your Option's Store"),
      values: Yup.array()
        .min(1, "Enter at least one value")
        .required("Please Enter Your Option's Values"),
    }),
    onSubmit: formValues => {
      if (isEdit) {
        const updateOption = {
          _id: option._id,
          segmentType: formValues.segmentType,
          values: formValues.values.map(formValue => formValue.value),
        }
        console.log("EDITING OPTION: ", formValues)
        // update option
        dispatch(onUpdateOption(updateOption))
        validation.resetForm()
      } else {
        console.log("CREATING OPTION: ", formValues)
        const newOption = {
          segmentType: formValues["segmentType"],
          company: formValues["company"],
          store: formValues["store"],
          values: formValues["values"].map(formValue => formValue.value),
        }
        // save new option
        dispatch(onAddNewOption(newOption))
        validation.resetForm()
      }
      toggle()
    },
  })

  const { values: formValues } = validation

  const { options } = useSelector(state => ({
    options: state.options.options,
  }))

  const { stores } = useSelector(state => ({
    stores: state.stores.stores,
  }))

  const { companies } = useSelector(state => ({
    companies: state.companies.companies,
  }))

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: options.length, // replace later with size(options),
    custom: true,
  }
  const { SearchBar } = Search

  const OptionColumns = toggleModal => [
    {
      dataField: "segmentType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
    },
    {
      dataField: "storeName",
      text: "Store",
      sort: true,
    },
    {
      dataField: "deleted",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.badgeclass}
          color={row.deleted ? "danger" : "success"}
          pill
        >
          {row.deleted ? "Deleted" : "Active"}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, option) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => handleOptionViewDetails(option)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, option) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleOptionClick(option)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(option)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOption(null)
    } else {
      setModal(true)
    }
  }

  const toggleViewModal = () => {
    if (modalViewDetails) {
      setViewDetailsModal(false)
      setOption(null)
    } else {
      setViewDetailsModal(true)
    }
  }

  const handleOptionClick = arg => {
    const option = arg

    setOption({
      _id: option._id,
      segmentType: option.segmentType,
      values: option.values,
      deleted: option.deleted,
      company: option.company,
      companyName: option.companyName,
      store: option.store,
      storeName: option.storeName,
      badgeclass: option.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  const handleOptionViewDetails = arg => {
    const option = arg

    setOption({
      _id: option._id,
      segmentType: option.segmentType,
      values: option.values,
      company: option.company,
      companyName: option.companyName,
      store: option.store,
      storeName: option.storeName,
      deleted: option.deleted,
      badgeclass: option.badgeclass,
    })

    toggleViewModal()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete option
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = option => {
    setOption(option)
    setDeleteModal(true)
  }

  const handleDeleteOption = () => {
    if (option._id) {
      dispatch(onDeleteOption(option))
      onPaginationPageChange(1)
      setDeleteModal(false)
    }
  }
  const handleOptionClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "_id",
      option: "desc",
    },
  ]

  useEffect(() => {
    if (isFirstTimeLoading.current && options) {
      isFirstTimeLoading.current = false
      dispatch(onGetOptions())
    }
  }, [dispatch, options])

  useEffect(() => {
    if (stores && !stores.length) {
      dispatch(onGetStores())
    }
  }, [dispatch, stores])

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies())
    }
  }, [dispatch, companies])

  useEffect(() => {
    if (companies && stores && options) {
      const newOptions = options
        .filter(option => !option.deleted)
        .map(option => ({
          ...option,
          companyName: companies.find(company => company._id === option.company)
            ?.name,
          storeName: stores.find(store => store._id === option.store)?.name,
        }))
      setOptionList(newOptions)
    }
  }, [stores, companies, options])

  useEffect(() => {
    if (companies) {
      const newCompanies = companies.filter(company => !company.deleted)
      setCompanyList(newCompanies)
    }
  }, [companies])

  useEffect(() => {
    if (stores && formValues.company) {
      const newStores = stores.filter(
        store => !store.deleted && store.company === formValues.company
      )
      setStoreList(newStores)
    }
  }, [stores, formValues.company])

  return (
    <React.Fragment>
      <OptionDetailsModal
        isOpen={modalViewDetails}
        toggle={toggleViewModal}
        option={option}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOption}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Options</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Options" breadcrumbItem="Options" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={OptionColumns(toggle)}
                    data={optionList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={optionList}
                        columns={OptionColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOptionClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Option
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    boptioned={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit Option" : "Add Option"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          {!isEdit && (
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Company
                                              </Label>
                                              <Input
                                                name="company"
                                                type="select"
                                                className="form-select"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values.company ||
                                                  ""
                                                }
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                invalid={
                                                  validation.touched.company &&
                                                  validation.errors.company
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {companyList.map(
                                                  (company, index) => (
                                                    <option
                                                      value={company._id}
                                                      key={company._id}
                                                    >
                                                      {company.name}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                              {validation.errors.company ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.company}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          )}

                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Type
                                            </Label>
                                            <Input
                                              name="segmentType"
                                              type="select"
                                              className="form-select"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.segmentType ||
                                                ""
                                              }
                                              validate={{
                                                required: { value: true },
                                              }}
                                              invalid={
                                                validation.touched
                                                  .segmentType &&
                                                validation.errors.segmentType
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option
                                                value="dropdown"
                                                key="dropdown"
                                              >
                                                Dropdown
                                              </option>
                                            </Input>
                                            {validation.touched.segmentType &&
                                            validation.errors.segmentType ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.segmentType}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <MultiTextInput
                                              label="Values"
                                              name="values"
                                              value={validation.values.values}
                                              onChange={
                                                validation.setFieldValue
                                              }
                                              error={validation.errors.values}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Options.propTypes = {
  options: PropTypes.array,
  onGetOptions: PropTypes.func,
  onAddNewOption: PropTypes.func,
  onDeleteOption: PropTypes.func,
  onUpdateOption: PropTypes.func,
}

export default withRouter(Options)
