/* TERMINALS */
export const GET_TERMINALS = "GET_TERMINALS"
export const GET_TERMINALS_SUCCESS = "GET_TERMINALS_SUCCESS"
export const GET_TERMINALS_FAIL = "GET_TERMINALS_FAIL"

/**
 * add TERMINAL
 */
export const ADD_NEW_TERMINAL = "ADD_NEW_TERMINAL"
export const ADD_TERMINAL_SUCCESS = "ADD_TERMINAL_SUCCESS"
export const ADD_TERMINAL_FAIL = "ADD_TERMINAL_FAIL"

/**
 * Edit TERMINAL
 */
export const UPDATE_TERMINAL = "UPDATE_TERMINAL"
export const UPDATE_TERMINAL_SUCCESS = "UPDATE_TERMINAL_SUCCESS"
export const UPDATE_TERMINAL_FAIL = "UPDATE_TERMINAL_FAIL"

/**
 * Delete TERMINAL
 */
export const DELETE_TERMINAL = "DELETE_TERMINAL"
export const DELETE_TERMINAL_SUCCESS = "DELETE_TERMINAL_SUCCESS"
export const DELETE_TERMINAL_FAIL = "DELETE_TERMINAL_FAIL"
